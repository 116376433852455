import React, { useState } from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  position: relative;
  width: 100%;
  z-index: 2;

  @media (max-width: 768px) {
    justify-content: flex-end;
    transform: translateY(40px);
  }

  .inputContainer{
    width: 90%;
    display: flex;
    justify-content: space-between;

  }
`;

const SearchInput = styled.input`
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 15px 0 0 15px;
  font-size: 16px;
  transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
  width: 80%;
  outline: none;
  transition: 0.3s;

  @media (max-width: 768px) {
    width: ${({ $isFocused }) => ($isFocused ? '80%' : '0')};
    /* margin-right: ${({ $isFocused }) => ($isFocused ? '10px' : '0')}; */
    opacity: ${({ $isFocused }) => ($isFocused ? '1' : '0')};
    pointer-events: ${({ $isFocused }) => ($isFocused ? 'auto' : 'none')};
  }

  &:hover{
    border-color: #8f8f8f;
  }

  &:focus{
    box-shadow: 0 0 0 2px #8f8f8f;
    }
`;

const SearchButton = styled.button`
  padding: 5px 20px;
  border: none;
  border-radius: 0 15px 15px 0;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  width: 20%;
  overflow: hidden;

  &:hover {
    background-color: #555;
  }

  @media (max-width: 768px) {
    opacity: ${({ $isFocused }) => ($isFocused ? '1' : '0')};
    pointer-events: ${({ $isFocused }) => ($isFocused ? 'auto' : 'none')};
    padding: 5px;
    font-size: 13px;
  }
`;

const SearchIcon = styled.div`
  padding: 5px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 10px #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* transform: translateY(35px); */
  ion-icon {
    font-size: 25px;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleSearch = () => {
    onSearch(query);
  };

  const toggleFocus = () => {
    setIsFocused((prev) => !prev);
  };

  return (
    <SearchContainer>
      <div className='inputContainer'>
        <SearchInput
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Rechercher un produit..."
            $isFocused={isFocused}
        />
        <SearchButton onClick={handleSearch} $isFocused={isFocused}>
            Rechercher
        </SearchButton>
      </div>
      <SearchIcon onClick={toggleFocus}>
        <ion-icon name={isFocused ? "close-outline" : "search-outline"}></ion-icon>
      </SearchIcon>
    </SearchContainer>
  );
};

export default SearchBar;
