import React, { useState } from 'react';
import styled from 'styled-components';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getToken } from '../../../../utils/Cookies/getCookie';
import Loader from '../../../../components/LoadButton';

const ShopLogoWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  background-color: #f0f0f0;
  border-radius: 50%;
  position: relative;

  img {
    width: 95px;
    height: 95px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 0 0 5px #0074d266;
  }
`;

const UploadButton = styled.div`
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.6;
`;

const LoadContainer = styled.div`
  position: absolute;
  /* bottom: 5px; */
  border-radius: 50%;
  background: #fbfbfbab;
  width: 100%;
  height: 100%;
`;

function ShopLogo({ isOwner, shopData }) {
  const generateShopLogo = (shopName) => {
    if (!shopName) return '';
    const words = shopName.split(' ');
    const initials = words.map((word) => word.charAt(0).toUpperCase()).join('');
    return initials;
  };

  const [loading, setLoading] = useState(false);
  const [shopLogo, setShopLogo] = useState(shopData.shop.logo || generateShopLogo(shopData.shop.shopName));
  const token = getToken();

  const handleChange = ({ file }) => {
    if (file.status === 'done') {
      setShopLogo(file.response.logo);
      message.success('Image modifié avec succès');
      setLoading(false);
    } else if (file.status === 'error') {
      message.error("Echec de téléchargement de l'image");
      setLoading(false);
    }
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('shopId', shopData.shop._id);

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shop/logo-upload`, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        onSuccess(result, file);
        setShopLogo(result.logo);
      } else {
        const error = new Error('Upload failed');
        onError(error);
      }
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ShopLogoWrapper>
      {shopLogo && typeof shopLogo === 'string' && shopLogo.startsWith('http') ? (
        <img src={shopLogo} alt="Shop Logo" />
      ) : (
        <h3>{shopLogo}</h3>
      )}

      {isOwner && (
        loading ? (
          <LoadContainer>
            <Loader />
          </LoadContainer>
        ) : (
          <UploadButton>
            <Upload
              name="photo"
              showUploadList={false}
              customRequest={customRequest}
              onChange={handleChange}
            >
              <Button icon={<UploadOutlined />}></Button>
            </Upload>
          </UploadButton>
        )
      )}
    </ShopLogoWrapper>
  );
}

export default ShopLogo;
