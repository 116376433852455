import React from "react";
import styled from "styled-components";
import { DeleteOutlined } from "@ant-design/icons";

const CartContainer = styled.div`
  bottom: 0;
  right: 0;
  width: 100%;
  background: #fff;
  padding: 0;

  * {
    transition: 0.5s;
  }
`;

const CartItem = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
  background: #f3f3f3;
  border-radius: 10px;

  .itemImages {
    display: flex;
    /* gap: 5px;*/
    margin-right: 10px;
    border-radius: 10px;
  }

  .itemImage {
    width: 50px; /* Ajustez la taille selon vos besoins */
    height: 70px; /* Ajustez la taille selon vos besoins */
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 10px;
  }

  .itemDetails {
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    flex-grow: 1;

    .itemName {
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      /* white-space: nowrap; */
    }

    .itemPrice {
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

const TotalAmount = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-top: 10px;
  border-top: 1px solid #ddd;
`;

const CustomSpan = styled.span`
  font-size: 11px;
  font-weight: 500;
  color: #9e9e9e;
`;

const DeleteIcon = styled(DeleteOutlined)`
  color: red;
  cursor: pointer;
  /* transform: translate(10px, -30px); */
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

const CheckoutButton = styled.button`
  padding: 10px 20px;
  background-color: #fca311;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #fdbe58;
  }

  ion-icon {
    font-size: 20px;
    margin-left: 5px;
  }
`;

const EmptyCartMessage = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  color: #999;
`;

function Cart({ cartItems, onRemove, ownerInfo, shopData }) {
  const totals = cartItems.reduce((acc, item) => {
    if (!acc[item.currency]) {
      acc[item.currency] = 0;
    }
    acc[item.currency] += item.price;
    return acc;
  }, {});

  const canalContact = shopData.shop.whatsapp ? shopData.shop.whatsapp : ownerInfo.whatsapp;

  const handleBuyNow = () => {
    const ShopUrl = `\n Plus de détails sur le shop ici : ${window.location.href}`;

    const message = cartItems
      .map(
        (item) =>
          `• Article: ${item.title}\nPrix: ${item.price} ${item.currency}\n `
      )
      .join("\n");

    const total = Object.keys(totals)
      .map(
        (currency) =>
          `✓ Total (${currency}): ${totals[currency].toFixed(2)} ${currency}`
      )
      .join("\n");

    const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(canalContact)}&text=${encodeURIComponent(
      `Bonjour, je souhaite passer à la caisse :\n\n${message}\n${total} \n${ShopUrl}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <CartContainer>
      {cartItems.length > 0 ? (
        <>
          {cartItems.map((item, index) => (
            <CartItem key={index}>
              {item.img && item.img.length > 0 && (
                <div className="itemImages">
                  <img
                    className="itemImage"
                    src={item.img[0]}
                    alt={`${item.title}-image`}
                  />
                </div>
              )}

              <div className="itemDetails">
                <span className="itemName">{item.title}</span>
                <span className="itemPrice">
                  {item.price} {item.currency}
                </span>
              </div>
              <DeleteIcon onClick={() => onRemove(item)} />
            </CartItem>
          ))}
          {Object.keys(totals).map((currency, index) => (
            <TotalAmount key={index}>
              <span>
                Total <CustomSpan>({currency})</CustomSpan>:
              </span>
              <span>
                {totals[currency].toFixed(2)} {currency}
              </span>
            </TotalAmount>
          ))}
          <CheckoutButton onClick={handleBuyNow}>
            <span> Passer à la caisse </span> <ion-icon name="card-outline"></ion-icon>
          </CheckoutButton>
        </>
      ) : (
        <EmptyCartMessage>Votre panier est vide</EmptyCartMessage>
      )}
    </CartContainer>
  );
}

export default Cart;
