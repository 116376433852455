import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, message } from 'antd';
import QRCode from 'qrcode.react';
import URLShortener from '../ShortLink'; // Assurez-vous que le chemin est correct

import PropTypes from 'prop-types';

const Container = styled.div`
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px #00000068;
    position: absolute;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        transform: scale(1.2);
    }

    ion-icon{
        color: #000;
        font-size: 30px;
    }
`;

const CustomModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 20px !important;
    }
`;

const CustomButton = styled.button`
    background: #000000;
    color: #fff;
    border-radius: 20px;
    padding: 10px 20px;
    border: 1px solid #000;
    transition: 0.3s;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
        color: #000;
        background: transparent;
    }

    @media screen and (max-width: 768px) {
        padding: 10px;
        font-size: 11px;
    }
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;

    .desc {
        padding: 5px;
        margin: 5px 0;
    }

    .location {
        display: flex;
        align-items: center;
        font-size: 11px;
        color: grey;
        margin: 10px 0;

        ion-icon {
            font-size: 15px;
            color: #007fe1;
        }
    }
`;

const DivLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid grey;
    border-radius: 0 20px 20px 0;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
    }

    button {
        outline: none;
        border: none;
        border-radius: 0 20px 20px 0;
        min-width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        background: #000;
        color: #fff;
        cursor: pointer;
        transition: 0.3s;
        border-left: 1px solid #5b5b5b;

        &:hover {
            background: #fff;
            color: #000;
        }

        &:focus {
            background: #000;
            color: #fff;
        }
    }
`;

const QRCodeContainer = styled.div`
    margin-top: 20px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 5px #c9c9c9;
    background: #ffffff;
`;

function Share({ shopData, ref4 }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [shopLogo, setShopLogo] = useState('');
    const [shortenedLink, setShortenedLink] = useState('');
    const [showShortLink, setShowShortLink] = useState(false);

    useEffect(() => {
        if (shopData.shop.logo && shopData.shop.logo === 'shopLogo.png') {
            setShopLogo(
                'https://play-lh.googleusercontent.com/5vcrZX1-Rx6NpuOASKSUWqMpQqbFTiLOZ-IV8CehAP3XycsmaKJvp36BJOxaKhq8TWc'
            );
        } else {
            setShopLogo(shopData.shop.logo);
        }
    }, [shopData]);

    const handleShare = () => {
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const handleCopyLink = () => {
        const currentURL = showShortLink ? shortenedLink : window.location.href;
    
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(currentURL).then(() => {
                message.success('Lien du shop copié avec succès');
            }).catch(err => {
                console.error('Erreur lors de la copie du lien :', err);
                message.error('Erreur lors de la copie du lien');
            });
        } else {
            // Alternative method for unsupported browsers
            const textArea = document.createElement("textarea");
            textArea.value = currentURL;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                message.success('Lien du shop copié avec succès');
            } catch (err) {
                console.error('Erreur lors de la copie du lien :', err);
                message.error('Erreur lors de la copie du lien');
            }
            document.body.removeChild(textArea);
        }
    };
    

    const downloadQRCode = () => {
        const canvas = document.getElementById('qrcode-canvas').querySelector('canvas');
        setShopLogo('');
        if (canvas) {
            try {
                const ctx = canvas.getContext('2d');
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

                // Créer un canvas temporaire pour le téléchargement
                const tempCanvas = document.createElement('canvas');
                tempCanvas.width = canvas.width;
                tempCanvas.height = canvas.height;
                const tempCtx = tempCanvas.getContext('2d');
                tempCtx.putImageData(imageData, 0, 0);

                // Convertir le canvas temporaire en URL et télécharger
                const url = tempCanvas.toDataURL();
                doDownload(url, 'QRCode.png');
            } catch (err) {
                console.error('Erreur lors de la création du QR Code :', err);
                message.error('Erreur lors de la génération du QR Code.');
            }
        } else {
            message.error('Impossible de trouver le QR Code à télécharger.');
        }
    };

    const doDownload = (url, fileName) => {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const toggleLink = () => {
        setShowShortLink(!showShortLink);
    };

    return (
        <Container>
            {/* <ion-icon name="share-social-outline" ></ion-icon> */}
            <ion-icon name="qr-code" onClick={handleShare} ref={ref4} ></ion-icon>

            <CustomModal
                title="Partager ce shop"
                open={modalVisible}
                onCancel={handleCancel}
                footer={[
                    <CustomButton key="copy" onClick={handleCopyLink}>
                        Copier le lien
                    </CustomButton>,
                    <CustomButton key="download" onClick={downloadQRCode}>
                        Télécharger le QR Code
                    </CustomButton>,
                ]}
            >
                <ModalContent>
                    <h3> • {shopData.shop.shopName} • </h3>

                    <QRCodeContainer id="qrcode-canvas">
                        <QRCode
                            value={window.location.href}
                            size={230}
                            imageSettings={{
                                src: shopLogo,
                                excavate: true,
                                height: 35,
                                width: 35,
                            }}
                        />
                    </QRCodeContainer>

                    <p className="location">
                        <ion-icon name="location"></ion-icon> {shopData.shop.shopAddress}
                    </p>

                    <DivLink>
                        <p className="shopLink">
                            {showShortLink ? `Lien court : ${shortenedLink}` : `Lien du shop : ${window.location.href}`}
                        </p>
                        <button key="toggle" onClick={toggleLink}>
                            {showShortLink ? <ion-icon name="unlink-outline"></ion-icon> : <ion-icon name="link-outline"></ion-icon>}
                        </button>
                    </DivLink>

                    {!shortenedLink && (
                        <URLShortener
                            longLink= {window.location.href}
                            onShortened={(shortUrl) => setShortenedLink(shortUrl)}
                        />
                    )}
                </ModalContent>
            </CustomModal>
        </Container>
    );
}

Share.propTypes = {
    shopData: PropTypes.object.isRequired,
    ref4: PropTypes.object,
};

export default Share;
