import React, { useState } from 'react';
import styled from 'styled-components';
import Balance from '../Balance'; // Assurez-vous que le chemin d'importation est correct
import { Chart } from 'react-chartjs-2';
import 'chart.js/auto';

const Container = styled.div`
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const Header = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const MoneyContainer = styled.div`
    padding: 0;
    /* background: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
`

const BalanceContainer = styled.div`
  /* margin-bottom: 20px; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  /* background: #027900; */

  @media screen and (max-width:768px){
    flex-direction: column;
    justify-content: center;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px auto;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: ${props => (props.disabled ? '#ccc' : '#000000')};
  color: white;

  &:hover {
    background-color: ${props => (props.disabled ? '#ccc' : '#292929')};
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
`;

const NotCertfyed = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000052;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 2px solid #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    p{
        color: #fff;
        font-weight: 600;
        text-align: center;
        text-shadow: 0 0 10px #fff;
    }
`

const Account = ({ user }) => {
    const [balance, setBalance] = useState(0); // Example initial balance
    const [chartData, setChartData] = useState([50]);

    const addMoney = () => {
        const newBalance = balance + 10;
        setBalance(newBalance);
        setChartData([...chartData, newBalance]);
    };

    const withdrawMoney = () => {
        if (balance > 10) {
            const newBalance = balance - 10;
            setBalance(newBalance);
            setChartData([...chartData, newBalance]);
        }
    };

    const data = {
        labels: chartData.map((_, index) => `T${index + 1}`),
        datasets: [
            {
                label: 'Affcher | Cacher le Chart',
                data: chartData,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
        ],
    };

    return (
        <Container>
            {user.certifyed && (
                <NotCertfyed>
                    <p> Vous devez être un utilisateur certifié pour acceder à cette section </p>
                </NotCertfyed>
            ) }
            <Header> Balance du compte </Header>
            <BalanceContainer>
                <Balance user={user} balance={balance} />

                <MoneyContainer>
                    {/* <h2> Solde actuel ${balance} </h2> */}

                    <ButtonsContainer>
                        <Button onClick={addMoney}> Recharger le compte </Button>
                        <Button onClick={withdrawMoney} disabled={balance <= 10}>
                            Retrait
                        </Button>
                    </ButtonsContainer>
                    <ChartContainer>
                        <Chart type="line" data={data} />
                    </ChartContainer>

                </MoneyContainer>

            </BalanceContainer>




        </Container>
    );
};

export default Account;
