import { useEffect, useState } from "react";
import { getToken, onMessage } from "firebase/messaging";
import styled, { keyframes } from "styled-components";
import { messaging } from "../../utils/firebase";

// Animation de vibration
const shake = keyframes`
  0% { transform: translateX(0); }
  20% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  60% { transform: translateX(-5px); }
  80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

const NotificationButton = styled.button`
  padding: 10px 20px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;

const PermissionContainer = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  left: 20px;
  background: #4d4d4d9c;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #ffffff;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  p {
    color: #fff;
    padding: 20px 0;
    display: flex;
    align-items: center;

    ion-icon {
      margin-right: 10px;
      font-size: 30px;
      animation: ${shake} 0.6s infinite; /* Application de l'animation */
    }
  }
`;

const NotificationRequest = ({ onTokenReceived, shopData }) => {
  const [permissionState, setPermissionState] = useState(null);

  useEffect(() => {
    const lastRequestTime = localStorage.getItem("lastNotificationRequestTime");
    const permission = Notification.permission;

    if (permission === "granted") {
      setPermissionState("granted");
    } else if (permission === "denied" && lastRequestTime) {
      const oneHour = 3600000;
      const timeSinceLastRequest = Date.now() - parseInt(lastRequestTime, 10);

      if (timeSinceLastRequest < oneHour) {
        setPermissionState("denied");
      } else {
        setPermissionState("default");
      }
    } else {
      setPermissionState("default");
    }
  }, []);

  const handlePermissionRequest = () => {
    Notification.requestPermission()
      .then((permission) => {
        localStorage.setItem("lastNotificationRequestTime", Date.now().toString());
        setPermissionState(permission);

        if (permission === "granted") {
          console.log("Notification permission granted.");
          return getToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
          });
        } else {
          console.log("Notification permission denied.");
          return null;
        }
      })
      .then((token) => {
        if (token) {
          console.log("FCM Token:", token);

          // Envoyez le token au serveur pour l'enregistrer pour des marketings
          sendVisitorDataToServer(token);
          
          if (onTokenReceived) {
            onTokenReceived(token);
          }
        }
      })
      .catch((err) => {
        console.log("Unable to get permission to notify.", err);
      });
  };

  const sendVisitorDataToServer = (token) => {
    const browserInfo = {
      userAgent: navigator.userAgent,
      browserName: navigator.appName,
      browserVersion: navigator.appVersion,
      os: navigator.platform,
    };

    console.log(browserInfo);

    const data = {
      browserInfo,
      pushToken: token,
    };

    fetch('http://localhost:3500/api/visitor/save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Visitor data sent successfully:', result);
      })
      .catch((error) => {
        console.error('Error sending visitor data:', error);
      });
  };


  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // Personnalisez la notification ici
      // Envoyez la notification avec le navigateur au premier plan
      // new Notification(payload.notification.title, {
      //   body: payload.notification.body,
      //   icon: "https://thumbs.dreamstime.com/b/lets-shopping-logo-design-template-shop-icon-135610500.jpg",  // Changez l'icône ici
      //   data: payload.data,  // Données supplémentaires
      // });

      // Créez une notification personnalisée
      const notification = new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: shopData.shop.logo,  // Changez l'icône ici
        data: payload.data,  // Données supplémentaires
      });

      // Ajouter un gestionnaire d'événements pour rediriger l'utilisateur lorsqu'il clique sur la notification
      notification.onclick = (event) => {
        event.preventDefault(); // Empêche le comportement par défaut
        
        const url = 'http://localhost:3000';
        // Redirige l'utilisateur vers l'URL spécifique
        if (payload.data && payload.data.shopId) {
          window.open(`${url}/shop/${payload.data.shopId}`, '_blank');
        } else {
          window.open(`${url}/Markeplace`, '_blank');  // Redirige vers la page d'accueil si shopId n'est pas disponible
        }
      };

    });
  }, []);

  return (
    <>
      {permissionState === "default" && (
        <PermissionContainer>
          <p>
            <ion-icon name="notifications-circle-outline"></ion-icon>
            Ne ratez rien, autorisez-moi à vous notifier
          </p>
          <NotificationButton onClick={handlePermissionRequest}>
            Autoriser
          </NotificationButton>
        </PermissionContainer>
      )}
    </>
  );
};

export default NotificationRequest;
