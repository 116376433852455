let googleMapsPromise = null;

export const loadGoogleMaps = () => {
    if (!googleMapsPromise) {
        googleMapsPromise = new Promise((resolve, reject) => {
            // Définissez votre clé API et les bibliothèques nécessaires ici
            const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
            // console.log('Google Maps API Key:', apiKey);
            const libraries = ['places'];

            // Créez le script d'inclusion de l'API Google Maps
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(
                ',',
            )}&callback=initMap&loading=async`;
            script.async = true;

            // Gestion des erreurs de chargement
            script.onerror = reject;

            // Chargement terminé
            window.initMap = () => resolve(window.google);

            // Ajoutez le script au corps du document
            document.body.appendChild(script);
        });
    }

    return googleMapsPromise;
};
