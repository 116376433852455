import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';
import VideoFond from '../../components/VideoFond';
import CreateShop from '../../components/CreateShop';
import './style.css';
import Loader from '../../components/LoadButton';
import Navbar from '../../components/Navbar';

import AuthModalContext from '../../utils/Context/AuthModal';
import Auth from '../../components/Auth';
import WhyUs from '../../components/WhyUs';

import Globe from '../../components/Globe';
import Cards from '../../components/Cards';
import Testimonials from '../../components/Testimonials';
import MenShopTemplate from '../../components/MenShopTemplate';
import CustomButton from '../../components/CustomButton';
import TrustedBrand from '../../components/TrustedBrand';
import Footer from '../../components/Footer';
import AccessShop from '../../components/AccessMyShop';
import NotificationRequest from '../../components/PushNotif';

const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    /* height: 100dvh; */
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;

    h2,
    p,
    span {
        color: #fff;
        padding: 15px 5px;
        /* text-align: left; */
    }

    h2 {
        font-size: 60px;
        max-width: 80%;
        font-family: 'Poppins';
        font-weight: 1000;
        line-height: 80px;
    }

    p {
        font-size: 17px;
    }

    span {
        font-size: 13px;
    }

    @media screen and (max-width: 768px) {
        h2 {
            font-size: 35px;
            line-height: 45px;
            text-align: left;
            max-width: 100%;
        }
    }
`;

const Header = styled.header`
    padding: 10px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

const ButtonAction = styled.button`
    padding: 15px 50px;
    border-radius: 25px;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    max-width: 40%;

    transform: translateY(40px);
    gap: 40px;
    font-size: 15px;
    outline: none;
    border: 1px solid transparent;
    /* background: #008ae6; */
    color: #000000;
    background: #fff;
    font-weight: 600;
    transition: 0.5s;

    &:hover {
        border: 2px solid #fff;
        background: transparent;
        color: #fff;
        box-shadow: 0 0 55px 0 #fff;
    }

    @media screen and (max-width: 768px) {
        max-width: 60%;
        padding: 15px 20px;
    }
`;

const GlobeContainer = styled.section`
    padding: 10px;
    width: 100%;
    height: 50vh;
    background-image: linear-gradient(#f4f4f4 1px, transparent 1px),
        linear-gradient(to right, #f4f4f4 2px, transparent 1px);
    background-size: 50px 50px;
    background-color: #ffffff;
    position: relative;
    display: flex;
    align-items: center;
    position: relative;

    div {
        position: absolute;
        top: 20px;

        button {
            /* background: red !important; */
            position: absolute;
            bottom: -330px;
            width: 260px;
        }
    }

    h2 {
        color: #444444;
        text-align: right;
        margin-right: 10px;

        @media screen and (max-width: 960px) {
            font-size: 40px;
        }
    }
`;

const Reasons = styled.section`
    padding: 20px;
    height: 100vh;
    /* border-radius: 150px; */
    width: 100%;
    background-image: linear-gradient(#f4f4f4 1px, transparent 1px),
        linear-gradient(to right, #f4f4f4 2px, transparent 1px);
    background-size: 50px 50px;
    background-color: #ffffff;
    position: relative;

    /* border-image: fill 0 linear-gradient(#000100, #000); */

    @media screen and (max-width: 768px) {
        height: 60vh;
    }
`;

function Home() {
    const [isCreateOpen, setCreateIsopen] = useState(false);

    const [showAuthModal, setshowAuthModal] = useState();

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    
    


    const handleTokenReceived = (token) => {
        console.log("FCM Token received:", token);
        // Envoyez le token au serveur pour l'enregistrer
        //Aboner le visiteur à la boutique pour lui signaler les nouveaux articles
      };

    // useEffect(() => {
    //     // Demander la permission de notification
    //     Notification.requestPermission()
    //       .then((permission) => {
    //         if (permission === "granted") {
    //           console.log("Notification permission granted.");
    //           return getToken(messaging, {
    //             vapidKey: "BLEnaR4CMtZ5FL7p-JyuhJVHV3xdVa6Ji5T7LyLY8p6tziMK-eti_MIulWXltWCwZAOGDJJ7yX1oiW5PurSurVE", // Remplacez par votre VAPID key
    //           });
    //         } else {
    //           console.log("Notification permission denied.");
    //           return null;
    //         }
    //       })
    //       .then((token) => {
    //         if (token) {
    //           console.log("FCM Token:", token);
    //           // Envoyez le token au serveur pour l'enregistrer
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("Unable to get permission to notify.", err);
    //       });
    
    //     // Gestion des messages en premier plan
    //     onMessage(messaging, (payload) => {
    //       console.log("Message received. ", payload);
    //       // Personnalisez la notification ici
    //     });
    //   }, []);






    function ShowCreateMenu() {
        setCreateIsopen(true);
        // console.log('opened');
    }

    let AuthModalValue = useContext(AuthModalContext);

    function handleChangeAuthContext() {
        if (AuthModalValue) {
            AuthModalValue = false;
        } else {
            AuthModalValue = true;
        }
        // console.log(AuthModalValue); // Vérifiez la nouvelle valeur après le changement
    }

    function deleteAllCookies() {
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        }
    }

    return (
        <HomeContainer>
            {/* <NotificationRequest onTokenReceived={handleTokenReceived} /> */}
            <Navbar />
            <AccessShop isAuthenticated={isAuthenticated} />
            <div className="auth-container">
                <Auth
                    showAuthModal={showAuthModal}
                    setshowAuthModal={setshowAuthModal}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                />
            </div>

            <Header>
                <h2>
                    {' '}
                    Créez votre <br /> boutique en ligne <br /> en quelques cliques, <br />{' '}
                    simplement.
                </h2>
                <p> Demarrez gratuitement, propulsez dès aujourd'hui </p>
                <span>
                    {' '}
                    Le E-commerce n'a jamais été simplifié, votre boutique en ligne vous appartient,
                    à vous d'en faire des ventes et benefices.
                </span>
                {/* Utilisez le bouton du Drawer pour ouvrir le Drawer */}
                <ButtonAction onClick={() => ShowCreateMenu(true)}>
                    {' '}
                    Créer ma boutique{' '}
                </ButtonAction>
                {/* <button onClick={deleteAllCookies}>Effacer tous les cookies</button> */}

                <VideoFond />
            </Header>

            <GlobeContainer>
                <Globe />
                <div>
                    <h2>
                        {' '}
                        Des milliers de boutiques et articles à découvrir, des tonnes de clients
                        partout.
                    </h2>
                    <CustomButton setCreateIsopen={setCreateIsopen} showAuthModal={showAuthModal}>
                        {' '}
                        Démarrer maintenant{' '}
                    </CustomButton>
                </div>
            </GlobeContainer>

            {/* <PushTokenAndDeviceInfo /> */}

            <Cards />

            <TrustedBrand />

            <Reasons>
                <WhyUs />
            </Reasons>

            <MenShopTemplate setCreateIsopen={setCreateIsopen} showAuthModal={showAuthModal} />

            {/* <Testimonials /> */}

            <Footer />

            <CreateShop
                isCreateOpen={isCreateOpen}
                setCreateIsopen={setCreateIsopen}
                showAuthModal={showAuthModal}
                setshowAuthModal={setshowAuthModal}
            />
        </HomeContainer>
    );
}

export default Home;
