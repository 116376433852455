import React, { useState } from "react";
import styled from "styled-components";
import { Carousel, Image } from "antd";
import ActionOnArticle from "./ActionOnArticle/ActionOnArticle";

const DrawerContentWrapper = styled.div`
  padding: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* background: red !important; */
`;

const ImgContainer = styled.div`
  width: 100%;
  max-height: 400px;
  border-radius: 10px;
  position: relative;

  ion-icon {
    font-size: 25px !important;
    background: #ffffffda;
    color: #212121;
    position: absolute !important;
    top: 50% !important;
    z-index: 3;
    border-radius: 5px;
    opacity: 0.6;
    backdrop-filter: blur(5px);
    border: 1px solid grey;
  }

  &:hover {
    opacity: 1;
  }

  #prev {
    left: 5px;
  }

  #next {
    right: 5px;
  }

  img {
    max-height: 400px;
    width: 100%;
    object-fit: contain;
    cursor: pointer;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

const CustomCarousel = styled(Carousel)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Informations = styled.div`
  padding: 20px 0;
  width: 100%;

  .titre-price {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */

    h2{

    }

    h4{
      max-width: 75%;
      text-align: left;
    }
  }

  p {
    text-align: left !important;
  }

  h1 {
    display: flex;
  }

  .additional-info {
    margin-top: 10px;
    padding: 5px;
    text-align: left;
    border: 1px solid #e5e5e5;
    border-radius: 5px;

    p {
      display: block;
      margin-top: 5px;
      color: #686868;
      font-size: 11px;
    }

    span{
      font-weight: 600;
    }
  }
`;

const DrawerActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const RapidActon = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddToCartButton = styled.button`
  padding: 5px 10px;
  background: ${(props) => (props.$buyNow ? "#fca311" : "#00c55f")}; /* Couleurs différentes pour les boutons */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => (props.$buyNow ? "#fca311" : "#00c55f")};
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: 0.3s;

  ion-icon {
    font-size: 25px;
    margin-left: 5px;
    transition: 0.3s;
  }

  &.loading {
    &:hover {
      cursor: not-allowed;

      ion-icon {
        transform: scale(0.6);
      }
    }
  }

  &:hover {
    ion-icon {
      transform: scale(1.2);
    }
  }

  &:last-child {
    margin-left: 10px;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
    padding: 5px;

    ion-icon {
      font-size: 15px;
    }
  }
`;

const Tips = styled.div`
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;

  h3 {
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    align-items: center;

    ion-icon {
      color: red;
      font-size: 25px;
      padding: 5px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px 0;
      border-bottom: 1px solid #ddd;
      font-size: 13px;
      line-height: 1.5;
      text-align: left;

      &:last-child {
        border-bottom: none;
      }

      ion-icon {
        font-size: 18px;
        color: #00d254;
        transform: translateY(5px);
      }
    }

    .copyright ion-icon {
      color: red;
    }
  }
`;

const randomImage = "https://via.placeholder.com/200";

function DrawerContent({
  isOwner,
  ownerInfo,
  article,
  handlePreview,
  currentImg,
  visible,
  setVisible,
  addToCart,
  shopData,
}) {
  const [addingToCart, setAddingToCart] = useState(false);

  const handleAddToCart = () => {
    setAddingToCart(true);
    addToCart(article);
    setTimeout(() => {
      setAddingToCart(false);
    }, 3000);
  };

  // console.log(shopData.shop.whatsapp)
  const handleBuyNow = () => {
    const localContact = (shopData.shop.whatsapp ? shopData.shop.whatsapp : ownerInfo.whatsapp).replace(/\s/g, '');
    // const whatsappNumber = ownerInfo.whatsapp.replace(/\s/g, '');

    const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(localContact)}&text=${encodeURIComponent(
      `Bonjour, je suis intéressé par l'article : \n• *${article.title}* \n✓ Au prix de *${article.price} ${article.currency}* \n\n Disponble sur votre shop : ${window.location.href}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <DrawerContentWrapper>
      <ImgContainer>
        {article.image && article.image.length > 1 ? (
          <CustomCarousel
            autoplay={false}
            arrows
            prevArrow={
              <ion-icon
                name="chevron-back-outline"
                style={{ color: "black" }}
                id="prev"
              ></ion-icon>
            }
            nextArrow={
              <ion-icon
                name="chevron-forward-outline"
                style={{ color: "black" }}
                id="next"
              ></ion-icon>
            }
          >
            {article.image.map((imgSrc, index) => (
              <div key={index}>
                <img
                  src={imgSrc}
                  alt={`Article image ${index + 1}`}
                  onClick={() => handlePreview(imgSrc)}
                />
              </div>
            ))}
          </CustomCarousel>
        ) : (
          <img
            src={
              article.image && article.image.length > 0
                ? article.image[0]
                : randomImage
            }
            alt={article.title}
            onClick={() =>
              handlePreview(article.image ? article.image[0] : randomImage)
            }
          />
        )}
        <Image
          src={currentImg}
          alt="Preview"
          style={{ display: "none" }}
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        />
      </ImgContainer>

      <Informations>
        <div className="titre-price">
          <h4>{article.title}</h4>
          <h2>
            {article.price} <span>{article.price && article.currency}</span>
          </h2>
        </div>
        <p>{article.description}</p>

        <div className="additional-info">
          <p> <span> {article.isDeliverable && "Livraison gratuite"} </span> </p>
          <p>Quantité en stock : <span> {article.stock} </span> </p>
        </div>
      </Informations>

      <DrawerActions>
        <RapidActon>
          {!isOwner && !addingToCart && (
            <AddToCartButton onClick={handleAddToCart}>
              <span> Ajouter au panier </span>
              <ion-icon name="cart-sharp"></ion-icon>
            </AddToCartButton>
          )}
          {!isOwner && addingToCart && (
            <AddToCartButton className="loading" disabled>
              <span> Déjà au panier </span>
              <ion-icon name="pricetags-sharp"></ion-icon>
            </AddToCartButton>
          )}
          {!isOwner && (
            <AddToCartButton $buyNow onClick={handleBuyNow}>
              <span> Acheter maintenant </span>
              <ion-icon name="card"></ion-icon>
            </AddToCartButton>
          )}
        </RapidActon>
      </DrawerActions>

      <ActionOnArticle isOwner={isOwner} shopData={shopData} article={article} />

      {!isOwner && (
        <Tips>
          <h3>
            <ion-icon name="alert-circle-outline"></ion-icon> Conseils très importants
          </h3>
          <ul>
            <li>
              <ion-icon name="checkmark-circle-outline"></ion-icon> Faites preuve de prudence et de vigilance lorsque vous achetez des articles en ligne.
            </li>
            <li>
              <ion-icon name="checkmark-circle-outline"></ion-icon> Vérifiez toujours la crédibilité du vendeur et consultez les avis des autres acheteurs.
            </li>

            <li> <ion-icon name="checkmark-circle-outline"></ion-icon> N'achetez que dans des boutiques en ligne de confiance, que vous connaissez déjà ou qui ont été recommandées par des amis ou des membres de votre famille.</li>
          <li> <ion-icon name="checkmark-circle-outline"></ion-icon> Évitez d'envoyer de l'argent à des vendeurs inconnus ou à des boutiques en ligne qui n'ont pas de réputation établie.</li>
          <li> <ion-icon name="checkmark-circle-outline"></ion-icon> Méfiez-vous des offres qui semblent trop belles pour être vraies.</li>
          <li> <ion-icon name="checkmark-circle-outline"></ion-icon> Soyez prudent lorsque des vendeurs demandent un paiement sans que vous n'ayez vus la marchandise. </li>
          <li> <ion-icon name="checkmark-circle-outline"></ion-icon> En cas de doute, contactez le service clientèle de la plateforme d'achat pour obtenir des conseils ou des informations supplémentaires.</li>
          <li> <ion-icon name="checkmark-circle-outline"></ion-icon> En suivant ces précautions, vous pouvez réduire le risque d'être victime d'une arnaque en ligne et profitez d'une expérience d'achat en ligne plus sûre et plus agréable.</li>


            <li className="copyright">
              <ion-icon name="alert-circle-outline"></ion-icon> elanga n'est en aucun cas responsable des transactions effectuées en dehors de notre la plateforme.
            </li>

          </ul>
        </Tips>
      )}
    </DrawerContentWrapper>
  );
}

export default DrawerContent;
