import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import './style.css';
import { getToken } from '../../utils/Cookies/getCookie';
import { useAuthModal } from '../../utils/Context/AuthModal';
import useUserLocation from '../../utils/CustomHook/useUserLocation';
import { loadGoogleMaps } from '../../utils/googleMaps';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const Container = styled.div`
    width: 100%;
    position: fixed;
    z-index: 2;
    bottom: 0;
    height: 60%;
    /* background: rgba(238, 238, 238, 0.9); */
    background: #56565688;
    border-radius: 45px 45px 0 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 30px #111111;
    transition: 0.3s;
    transform: ${(props) => (props.$isCreateOpen ? 'translateY(0%)' : 'translateY(100%)')};
`;

const Smooth = styled.div`
    width: 30%;
    height: 7px;
    border-radius: 10px;
    position: absolute;
    background: #b7b7b7;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: 0.3s;
    z-index: 1;

    &:hover {
        transform: translate(-50%, -50%) scale(1.1);
    }
`;

const Formulaire = styled.form`
    padding: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;

    input,
    textarea {
        outline: none;
        border: 1px solid #fff;
        border-radius: 20px;
        background: transparent;
        width: 100%;
        margin: 10px 0;
        font-size: 15px;
        resize: none;
        transition: 0.3s;
        font-weight: 600;
        padding: 15px;
        border-radius: 25px;

        &:focus {
            box-shadow: 0 0 15px 0px #ffffff;
            background: #f4f4f46b;
        }

        &::placeholder {
            font-weight: 400;
            color: #fff;
        }
    }
    textarea {
        padding: 10px 10px;
        border-radius: 10px;
    }

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;

const ButtonDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    left: 0;
    margin-top: 10px;

    button {
        padding: 15px 50px;
        border-radius: 25px;
        margin-top: 5px;
        gap: 40px;
        font-size: 15px;
        outline: none;
        border: 1px solid #fff;
        width: 100%;
        /* background: #008ae6; */
        /* color: #fff; */
        background: #fff;
        font-weight: 600;
        color: #000;
        cursor: pointer;
    }
`;

const StepContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.$active ? 1 : 0)};
    transform: ${(props) =>
        props.$active
            ? 'translateX(0%)'
            : props.direction === 'next'
                ? 'translateX(100%)'
                : 'translateX(100%)'};
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
`;

function CreateShop({ isCreateOpen, setCreateIsopen }) {
    const [step, setStep] = useState(1);
    const [userLocation, setUserLocation] = useState('');
    const inputRef = useRef(null);
    const [formData, setFormData] = useState({
        shopName: '',
        shopDescription: '',
        shopAddress: '',
        businessCategory: [],
        shopCoordsBack: '',
        selectedAddressCoordinates: '',
    });

    const { authModal, setAuthModal } = useAuthModal();
    const { location, coords, country, cities } = useUserLocation();

    const [selectedCoordinates, setSelectedCoordinates] = useState({ lat: null, lng: null });

    useEffect(() => {
        if (coords) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                shopCoordsBack: `${coords.latitude}, ${coords.longitude}`,
            }));
        }
    }, [coords]);

    useEffect(() => {
        const initAutocomplete = async () => {
            const google = await loadGoogleMaps(); // Attendre que Google Maps soit chargé
            if (inputRef.current && google && google.maps) {
                const autocomplete = new google.maps.places.Autocomplete(inputRef.current);
                autocomplete.setFields(['address_components', 'formatted_address', 'geometry']);
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place && place.formatted_address && place.geometry) {
                        const addressParts = place.formatted_address.split(',');
                        const city = addressParts[0].trim();
                        const lat = place.geometry.location.lat();
                        const lng = place.geometry.location.lng();
                        setFormData((prevFormData) => ({
                            ...prevFormData,
                            shopAddress: place.formatted_address,
                            selectedAddressCoordinates: `${lat}, ${lng}`,
                        }));
                        setUserLocation(city);
                        setSelectedCoordinates({ lat, lng });
                        // console.log('Coordinates:', { lat, lng });
                    }
                });
            }
        };

        initAutocomplete();
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        if (step === 1) {
            return formData.shopName.trim() !== '';
        } else if (step === 2) {
            return formData.shopDescription.trim() !== '';
        } else if (step === 3) {
            return (
                formData.shopAddress.trim() !== '' &&
                formData.businessCategory &&
                formData.businessCategory.length > 0
            );
        } else {
            message.error("Veuillez remplir tous les champs");
        }
    };

    const [loading, setLoading] = useState(false);

    const token = getToken();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Vérifier si le formulaire est valide avant de soumettre
        if (!validateForm()) {
            return;
        }

        // Ajouter les coordonnées au formData
        const updatedFormData = {
            ...formData,
            shopCoordsBack: {
                type: 'Point',
                coordinates: coords ? [coords.longitude, coords.latitude] : [0, 0],
            },
            selectedAddressCoordinates: {
                type: 'Point',
                coordinates: selectedCoordinates
                    ? [selectedCoordinates.lng, selectedCoordinates.lat]
                    : [0, 0],
            },
        };

        try {
            setLoading(true); // Activer le chargement du bouton
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shop/`, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedFormData),
            });

            if (!response.ok) {
                // message.error("Une erreur s'est produite, réessayez !");

                if (response.status === 401) {
                    message.warning("Veuillez vous connecter pour pouvoir créer votre shop en ligne!!");
                    setAuthModal(!authModal);
                }
            }

            if (response.status === 201) {
                message.success("Félicitations, votre shop a été créé !");
                setCreateIsopen(false);
            }
        } catch (error) {
            console.error(error);
            message.error("Une erreur s'est produite, réessayez !");
        } finally {
            setLoading(false); // Désactiver le chargement du bouton
        }
    };

    const handleNextStep = () => {
        if (!validateForm()) {
            message.error("Veuillez remplir tous les champs !");
            return;
        }

        setStep(step + 1);
    };

    const handlePrevStep = () => {
        setStep(step - 1);
    };

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectChange = (value) => {
        setSelectedOptions(value);
        setFormData({
            ...formData,
            businessCategory: Array.isArray(value) ? value : [value],
        });
    };

    const containerRef = useRef(null);

    const handleCreateShopContainerClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setCreateIsopen(false);
        }
    };

    return (
        <div
            className={`CreateShop-Container ${isCreateOpen ? 'active' : ''}`}
            onClick={handleCreateShopContainerClick}
        >
            <Container $isCreateOpen={isCreateOpen} ref={containerRef}>
                <Smooth />
                <StepContainer $active={step === 1} direction="next">
                    <Step1
                        formData={formData}
                        handleInputChange={handleInputChange}
                        handleNextStep={handleNextStep}
                    />
                </StepContainer>
                <StepContainer $active={step === 2} direction="next">
                    <Step2
                        formData={formData}
                        handleInputChange={handleInputChange}
                        handlePrevStep={handlePrevStep}
                        handleNextStep={handleNextStep}
                    />
                </StepContainer>
                <StepContainer $active={step === 3} direction="prev">
                    <Step3
                        formData={formData}
                        handleInputChange={handleInputChange}
                        handleSubmit={handleSubmit}
                        loading={loading}
                        selectedOptions={selectedOptions}
                        handleSelectChange={handleSelectChange}
                        containerRef={containerRef}
                        inputRef={inputRef}
                        selectedCoordinates={selectedCoordinates}
                        coords={coords}
                    />
                </StepContainer>
            </Container>
        </div>
    );
}

export default CreateShop;
