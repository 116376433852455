import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.footer`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  background-image: radial-gradient(#cecece 1px, transparent 1px);
    background-size: 22px 22px;
    background-color: #ffffff;
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    width: auto;
  }
`;

const StyledLink = styled(Link)`
  color: #232323;
  text-decoration: none;
  margin: 5px 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.1);
    font-weight: 600;
  }
`;

const Copyright = styled.p`
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  color: #aeaeae;

  span{
    font-weight: 600;
    color: #2c2c2c;
  }

  @media (min-width: 768px) {
    text-align: right;
  }
`;

const Footer = () => {
  return (
    <Container>
      <LinksContainer>
        <StyledLink to="/"> <ion-icon name="planet"></ion-icon> Home </StyledLink>
        <StyledLink to="/Marketplace"> <ion-icon name="storefront-outline"></ion-icon> MarketPlace </StyledLink>
      </LinksContainer>
      <Copyright>© {new Date().getFullYear()} <span> elanga </span> | Powered by safaridew </Copyright>
    </Container>
  );
};

export default Footer;
