import React, { useState } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Image, Upload, Button, Input, Form, Checkbox, InputNumber, Select, message } from 'antd';
import styled from 'styled-components';
import { getToken } from '../../../../utils/Cookies/getCookie';
import Loader from '../../../../components/LoadButton';

const { Option } = Select;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const Container = styled.div`
    margin-top: 20px;

    .ant-upload-list{
        display: flex;
        justify-content: center;
    }

    .ant-modal-content{
        border-radius: 20px !important;
    }
`;

const UploadContainer = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FormContainer = styled.div`
    padding: 10px 0;

    .isDeliverable{
        margin-bottom: -40px;
    }
`;

const CustomInput = styled(Input)`
    border: 1px solid #848484;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;

    &:focus {
        border-color: #188fff;
        box-shadow: none;
    }
`;


const CustomTextArea = styled(Input.TextArea)`
    border: 1px solid #848484;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 5px;

    &:focus {
        border-color: #188fff;
        box-shadow: none;
    }
`;

const CustomButton = styled(Button)`
    && {
        background-color: #000 !important;
        border: none !important;
        color: white !important;
        padding: 20px 45px !important;
        border-radius: 20px;
        border: 1px solid #000 !important;
        transition: 0.3s;
        font-weight: 500;

        &:hover {
            background-color: #fff !important;
            color: #000 !important;
            border-color: #000 !important;
        }
    }
`;

const PriceContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid grey;
    border-radius: 10px;
    height: 45px;
    overflow: hidden;
    background-color: #fff;

    .ant-input {
        width: 80% !important;
        height: 100% !important;
        border: none !important;

        &:focus {
            box-shadow: none;
        }
    }

    .ant-select {
        width: 20% !important;
        height: 100%;
        padding: 0 !important;
    }
`

const CustomSelect = styled(Select)`
    border-left: 2px solid grey;
    padding: 10px !important;
    margin-left: 10px;
    width: 100px !important;

    .ant-select-selector {
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background: #ececec !important;
        height: 100% !important;
        padding: 0 !important;
        border-radius: 0 !important;
    }

    .ant-select-selection-item {
        background: #ececec !important;
        position: relative;
    }

    .ant-select-arrow {
        color: #000 !important;
        position: absolute;
        right: 5px !important;
        top: 55% !important;
        transform: translate(-30%, -25%) !important;
        font-weight: 700;
    }
`;

const CustomCheckbox = styled(Checkbox)`
    margin: 30px 0;
    .ant-checkbox {
        width: 24px; /* largeur de la checkbox */
        height: 24px; /* hauteur de la checkbox */
    }
    .ant-checkbox-inner {
        width: 24px; /* largeur de l'intérieur de la checkbox */
        height: 24px; /* hauteur de l'intérieur de la checkbox */
        border-radius: 10px;
        border: 2px solid #e2e2e2;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
        width: 8px; /* largeur de la coche */
        height: 16px; /* hauteur de la coche */
    }
`;

const QuantityContainer = styled.div`
    display: flex;
    align-items: center;
    /* margin-bottom: 15px; */
    padding: 0;

    .ant-input-number {
        border-radius: 20px;
        margin-left: 10px;
    }
`;

const QuantityButton = styled(Button)`
    && {
        border: 1px solid #848484 !important;
        border-radius: 20px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
    }
`;

function AddArticles(props) {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();
    const [isUnlimitedStock, setIsUnlimitedStock] = useState(true);
    const [quantity, setQuantity] = useState(1);
    const  [loading, setIsLoading] = useState(false);

    const { shopData } = props;

    const token = getToken();

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const handleStockChange = (checked) => {
        setIsUnlimitedStock(checked);
    };


    const handleSubmit = async (values) => {

        const formData = new FormData();

         // Vérifier si au moins une image a été sélectionnée
        if (fileList.length === 0) {
            message.warning('Veuillez sélectionner au moins une image.');
            return; // Arrêter la soumission du formulaire si aucune image n'est sélectionnée
        }

        // Vérifier que tous les champs requis sont renseignés
        if (!values.title || !values.description || !values.price || !values.currency) {
            message.error('Veuillez remplir tous les champs requis.');
            return; // Arrêter la soumission du formulaire si des champs requis sont manquants
        }

        fileList.forEach((file) => {
            if (file.originFileObj) {
                formData.append('photo', file.originFileObj);
            }
        });

        formData.append('shopId', shopData.shop._id);
        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('price', values.price);
        formData.append('currency', values.currency);
        formData.append('isUnlimitedStock', isUnlimitedStock);
        formData.append('isDeliverable', values.isDeliverable);
        formData.append('stock', isUnlimitedStock ? 'illimité' : quantity);

        if (!isUnlimitedStock) {
            formData.append('quantity', quantity);
        }

        setIsLoading(true)
        try {
            const response = await fetch('http://localhost:3500/api/articles/add', {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                console.log('Success:', data);
                // Reset form and file list
                form.resetFields();
                setFileList([]);
                setIsUnlimitedStock(true);
                setQuantity(1);

                setIsLoading(false)

                message.success('L\'article a bien été ajouté !'); 

                setTimeout(() => {
                    window.location.reload();
                }, 5000);

            } else {
                console.error('Error:', data);
            }
        } catch (error) {
            console.error('Error:', error);
        }finally{
            setIsLoading(false)
        }
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Ajout image</div>
        </div>
    );

    const increaseQuantity = () => {
        if (quantity < 10) {
            setQuantity(quantity + 1);
        }
    };

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    return (
        <Container>
            <UploadContainer>
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    beforeUpload={() => false} // Empêche le téléversement automatique
                    multiple
                >
                    {fileList.length >= 8 ? null : uploadButton}
                </Upload>
                {previewOpen && (
                    <Image
                        style={{ display: 'none' }}
                        preview={{
                            visible: previewOpen,
                            src: previewImage,
                            onVisibleChange: (value) => setPreviewOpen(value),
                        }}
                    />
                )}
            </UploadContainer>

            <FormContainer>
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="title"
                        rules={[
                            { required: true, message: "Veuillez entrer le titre de l'article." },
                        ]}
                    >
                        <CustomInput placeholder="Titre de l'article" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: "Veuillez entrer les détails de l'article.",
                            },
                        ]}
                    >
                        <CustomTextArea
                            autoSize={{ minRows: 5 }}
                            placeholder="Détails de l'article"
                        />
                    </Form.Item>
                    <PriceContainer>
                        <Form.Item
                            name="price"
                            rules={[
                                {
                                    required: true,
                                    message: "Veuillez entrer le prix de l'article.",
                                },
                            ]}
                            noStyle
                        >
                            <CustomInput placeholder="Prix" type='number' />
                        </Form.Item>
                        <Form.Item
                            name="currency"
                            initialValue="USD"
                            rules={[
                                { required: true, message: 'Veuillez sélectionner la devise.' },
                            ]}
                            noStyle
                        >
                            <CustomSelect>
                                <Option value="USD"> USD </Option>
                                <Option value="EUR"> EUR </Option>
                                <Option value="CDF"> CDF </Option>
                                <Option value="XOF"> XOF </Option>
                                {/* <Option value="FCFA"> FCFA </Option> */}
                                <Option value="GBP"> GBP </Option>
                                <Option value="JPY"> JPY </Option>
                            </CustomSelect>
                        </Form.Item>
                    </PriceContainer>

                    <Form.Item name="isDeliverable" valuePropName="checked" initialValue={false} className='isDeliverable'>
                        <CustomCheckbox> Livraison gratuite </CustomCheckbox>
                    </Form.Item>

                    <QuantityContainer>
                        <CustomCheckbox
                            checked={isUnlimitedStock}
                            onChange={(e) => handleStockChange(e.target.checked)}
                        >
                            {/* Stock illimité */}
                            {isUnlimitedStock ? 'Stock illimité' : 'Quantité'}
                        </CustomCheckbox>
                        {!isUnlimitedStock && (
                            <>
                                <QuantityButton onClick={decreaseQuantity}>
                                    <MinusOutlined />
                                </QuantityButton>
                                <InputNumber
                                    min={1}
                                    max={10}
                                    value={quantity}
                                    onChange={(value) => setQuantity(value)}
                                />
                                <QuantityButton onClick={increaseQuantity}>
                                    <PlusOutlined />
                                </QuantityButton>
                            </>
                        )}
                    </QuantityContainer>

                    <Form.Item>
                        <CustomButton htmlType="submit" disabled={loading} >
                            {loading ? <Loader /> : "Ajouter l'article"}
                        </CustomButton>
                    </Form.Item>
                </Form>
            </FormContainer>
        </Container>
    );
}

export default AddArticles;
