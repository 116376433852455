// SearchBar.js
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SearchInput = styled.input`
  padding: 8px 20px;
  border: 2px solid #ccc;
  background: transparent;
  outline: none;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
`;

const SearchBar = ({ onSearch, ref3 }) => {
  const [query, setQuery] = useState('');

  const handleChange = (e) => {
    setQuery(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <SearchInput
      type="text"
      placeholder="Rechercher un shop par nom, catégorie ou lieu"
      value={query}
      onChange={handleChange}
      ref={ref3}
    />
  );
};

SearchBar.propTypes = {
    ref3: PropTypes.object.isRequired,
  };

export default SearchBar;
