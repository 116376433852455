import styled from "styled-components";
import elangaLogo from '../../../../assets/img_marque/logo-color.svg';

const Container = styled.footer`
    padding: 20px;
    margin-top: 30px;
    border-top: 2px solid #dbdbdb;
    text-align: center;
    position: relative;
    padding-bottom: 40px;
    
    .copyright{
        position: absolute;
        bottom: 5px;
        left: 5px;
        font-size: 11px;
        color: #5e5e5e;

        span{
            color: #000;
            font-weight: 700;
        }
    }
`;

const Links = styled.div`
    margin-bottom: 20px;
`;

const SocialMedias = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
        display: grid;
        grid-template-columns: repeat(auto-fill, 150px);
        gap: 10px; 
    }
`;

const SocialLink = styled.a`
    margin: 0 10px;
    color: #555;
    text-decoration: none;
    display: flex;
    align-items: center;
    text-align: left;

    img{
        width: 50px;
    }

    &:hover {
        color: #007bff;
    }
`;

function Footer({ shopData }) {
    const tiktokLink = shopData.shop.tiktokLink ? `https://www.tiktok.com/@${shopData.shop.tiktokLink}` : 'https://www.tiktok.com/@elanga_app';
    const facebookLink = shopData.shop.facebookLink ? `https://www.facebook.com/${shopData.shop.facebookLink}` : 'https://www.facebook.com/profile.php?id=61555541251910';
    const instagramLink = shopData.shop.instagramLink ? `https://www.instagram.com/${shopData.shop.instagramLink}` : 'https://www.instagram.com/safaridew_';

    return (
        <Container>
            <SocialMedias>
                <SocialLink href="/" target="_blank" rel="noopener noreferrer">
                    <ion-icon name="planet"></ion-icon> Home
                </SocialLink>
                <SocialLink href={facebookLink} target="_blank" rel="noopener noreferrer">
                    <ion-icon name="logo-facebook"></ion-icon> Facebook
                </SocialLink>
                <SocialLink href={instagramLink} target="_blank" rel="noopener noreferrer">
                    <ion-icon name="logo-instagram"></ion-icon> Instagram
                </SocialLink>
                <SocialLink href={tiktokLink} target="_blank" rel="noopener noreferrer">
                    <ion-icon name="logo-tiktok"></ion-icon> TikTok
                </SocialLink>
            </SocialMedias>

            <p className="copyright"> Made with <span>elanga</span> </p>
        </Container>
    );
}

export default Footer;
