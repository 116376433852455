import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
    position: relative;
    color: #1d1d1d !important;
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    align-items: first baseline;
    padding-bottom: 80px;

    background-image: radial-gradient(#cecece 1px, transparent 1px);
    background-size: 22px 22px;
    background-color: #ffffff;

    @media screen and (max-width: 768px) {
        padding: 10px;
        justify-content: space-between;
        padding-bottom: 80px;
    }
`;

const FooterLinks = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    a {
    }
`;

const FooterLink = styled(Link)`
    color: #1d1d1d;
    margin: 5px;
    text-decoration: none;
    text-align: left;
    font-size: 16px;
    transition: 0.3s ease;
    display: flex;
    align-items: center;

    &:hover {
        transform: translateX(10px);

        ion-icon {
            transform: translateX(-10px);
            color: #008ae6;
        }
    }

    ion-icon {
        font-size: 20px;
        transition: 0.5s;
        margin-right: 2px;
    }
`;

const FooterData = styled.div`
    margin-top: 20px;
    font-size: 14px;
    color: #1d1d1d;

    p {
        color: #1d1d1d;
    }
`;

const LinkPro = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
`;

const SocialAdrr = styled.div`
    display: flex;
    flex-direction: column;

    p {
        display: flex;
        align-items: center;
        color: #2a2a2a !important;
    }
`;

const Social = styled.div`
    padding: 5px 0;
    border: 1px dotted grey;
    border-radius: 10px;

    div {
        display: flex;
        justify-content: space-evenly;
    }

    ion-icon {
        font-size: 25px;
        cursor: pointer;
        color: #2f2f2f;
        transition: 0.3s;

        &:hover {
            transform: scale(1.1);
        }
    }
`;

const Copyright = styled.div`
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    p {
        font-size: 13px !important;
        color: #4a4a4a !important;
    }
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterLinks>
                <FooterLink to="/About"> A propos </FooterLink>
                <FooterLink to="/Termes"> Termes & conditions </FooterLink>
                <FooterLink to="/Politique"> Politique de conf. </FooterLink>
                <FooterLink to="/"> Accueil </FooterLink>
            </FooterLinks>

            <FooterData>
                <LinkPro>
                    <FooterLink to="https://wa.me/243850605759" target="_blank">
                        {' '}
                        <ion-icon name="logo-whatsapp"></ion-icon> Nous contacter{' '}
                    </FooterLink>
                    <FooterLink to="/Marketplace">
                        {' '}
                        <ion-icon name="storefront-outline"></ion-icon> Marketplace{' '}
                    </FooterLink>
                    <FooterLink to="https://www.safaridew.com" target="_blank">
                        {' '}
                        Voir safaridew{' '}
                    </FooterLink>
                </LinkPro>

                <SocialAdrr>
                    <p>
                        {' '}
                        <ion-icon name="location"></ion-icon> Lubumbashi, RDC
                    </p>

                    <Social>
                        <h4> Suivre </h4>
                        <div>
                            <Link to="https://www.tiktok.com/@safaridew.com" target="_blank">
                                {' '}
                                <ion-icon name="logo-tiktok"></ion-icon>
                            </Link>
                            <Link to="https://www.facebook.com/safaridew" target="_blank">
                                {' '}
                                <ion-icon name="logo-facebook"></ion-icon>
                            </Link>

                            <Link to="https://www.instagram.com/safaridew_" target="_blank">
                                {' '}
                                <ion-icon name="logo-instagram"></ion-icon>
                            </Link>
                        </div>
                    </Social>
                </SocialAdrr>
            </FooterData>

            <Copyright>
                <p>&copy; {new Date().getFullYear()} elanga | Tous droits réservés.</p>
            </Copyright>
        </FooterContainer>
    );
};

export default Footer;
