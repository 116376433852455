import styled from "styled-components";
import ShopMenu from "../Menu";
import PropTypes from 'prop-types';

const Nav = styled.nav`
    padding: 5px;
`

function ShopNav( {isOwner, shopData, ownerInfo, ref2}){
    return(
        <Nav>
            <ShopMenu isOwner={isOwner} shopData={shopData} ownerInfo={ownerInfo} ref2={ref2} />
        </Nav>
    )
}

ShopNav.propTypes = {
    ref2: PropTypes.object.isRequired,
};

export default ShopNav;