import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import getRandomImage from '../../../../utils/getRandomImage';
import ShopNav from '../ShopNav';
import Share from '../ShareShop';
import PropTypes from 'prop-types';
import ShopMap from '../ShopMap';
// import { LoadScript } from '@react-google-maps/api';
import loadGoogleMaps from '../../../../utils/googleMaps';
import translateCategory from '../../../../utils/categoryTranslations';

const defaultImages = [
  'https://plus.unsplash.com/premium_photo-1683288062196-bdb1c61c7b95?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://plus.unsplash.com/premium_photo-1683288295841-782fa47e4770?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://plus.unsplash.com/premium_photo-1683288446478-d998f96592e0?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://plus.unsplash.com/premium_photo-1683288296165-4c2d6af3d776?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1683313060361-da015696d16f?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',

];


//   const defaultImages = [
//     'https://images.pexels.com/photos/264547/pexels-photo-264547.jpeg',
//     'https://images.pexels.com/photos/374132/pexels-photo-374132.jpeg',
//     'https://images.pexels.com/photos/2422254/pexels-photo-2422254.jpeg',
//     'https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg',
//     'https://images.pexels.com/photos/374870/pexels-photo-374870.jpeg',
//   ];


const Header = styled.header`
  padding: 5px;
  position: relative;
  border-radius: 5px;
  height: 50vh;
  width: 100%;
  background: url(${props => props.$backgroundImage || defaultImages});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  color: #fff;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Overlay sombre */
    /* background: linear-gradient(45deg, rgba(0, 0, 0, 0.926), rgba(255, 255, 255, 0)); */
    border-radius: 5px;
    z-index: 0;
  }

  .shopName {
    text-align: center;
    margin: auto;
    font-size: 1.5rem;
    font-weight: bold;
    z-index: 1;
    padding: 0.5rem;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  }

  .category {
    text-align: left;
    color: #fff;
    font-size: 1.2rem;
    padding-left: 10px;
    margin-bottom: 80px;
  }

  @media screen and (max-width:768px) {
    height: 50vh;
  }
`;

const DataFloat = styled.div`
  position: absolute;
  left: 10px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
`;

const IconWrapper = styled.div`
  height: 50px;
  width: 50px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #ffffff29;
  backdrop-filter: blur(5px);
  border-radius: 15px;
  border: 1px solid #fff;
  color: #fff;

  & > p {
    display: ${props => (props.isVisible ? 'block' : 'none')};
    margin-left: 10px;
    transform: translateX(80px);
  }

  ion-icon{
    font-size: 1.5rem;
    color: #fff;
  }
`;

const Adress = styled.div`
  padding: 5px;
  bottom: 5px;
  left: 5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
`;


function ShopHeader({ shopData, isOwner, ownerInfo, ref2, ref4 }) {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [visibleText, setVisibleText] = useState({});


  // useEffect(() => {
  //   async function fetchImage() {
  //     let imageUrl = await getRandomImage(shopData.shop.businessCategory);

  //     // Si getRandomImage ne retourne pas d'image, on choisit une image aléatoire dans le tableau defaultImages
  //     if (!imageUrl) {
  //       const randomIndex = Math.floor(Math.random() * defaultImages.length);
  //       imageUrl = defaultImages[randomIndex];
  //     }
  //     setBackgroundImage(imageUrl);
  //     // console.log(imageUrl);
  //   }
  //   fetchImage();
  // }, [shopData.shop.businessCategory]);

  // =================== REQUEST BLOQUED BY CORS POLICY =====================

  useEffect(() => {
    async function fetchImage() {
      let formattedCategory = shopData.shop.businessCategory[0];
      // console.log(formattedCategory);

      if (typeof formattedCategory === 'string') {
        // Traduire la catégorie en anglais à partir du dictionnaire
        formattedCategory = translateCategory(formattedCategory);

        // Remplacer les espaces et les caractères spéciaux par un "+"
        formattedCategory = formattedCategory.replace(/[^a-zA-Z0-9]/g, "+");
      } else {
        // console.error("businessCategory is not a string:", formattedCategory);
        formattedCategory = ""; // Valeur par défaut
      }

      const imageUrl = await getRandomImage(formattedCategory);

      if (!imageUrl) {
        const randomIndex = Math.floor(Math.random() * defaultImages.length);
        setBackgroundImage(defaultImages[randomIndex]);
      } else {
        setBackgroundImage(imageUrl);
      }
    }

    fetchImage();
  }, [shopData.shop.businessCategory]);



  const handleToggleText = (key) => {
    setVisibleText(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const G_key = process.env.REACT_APP_GOOGLE_MAPS_KEY;

  return (
    <Header $backgroundImage={backgroundImage}>
      <ShopNav isOwner={isOwner} shopData={shopData} ownerInfo={ownerInfo} ref2={ref2} />
      <h3 className='shopName'> {shopData.shop.shopName} </h3>
      <DataFloat>

        <ShopMap shopData={shopData} />
        {/* <LoadScript
          googleMapsApiKey={G_key}
          onLoad={() => console.log("Google Maps script loaded successfully!")}
        >
          <ShopMap shopData={shopData} />
        </LoadScript> */}

        <IconWrapper isVisible={visibleText['category']} onClick={() => handleToggleText('category')}>
          <ion-icon name="sparkles"></ion-icon>
          {visibleText['category'] && <p>{shopData.shop.businessCategory}</p>}
        </IconWrapper>
        <IconWrapper isVisible={visibleText['address']} onClick={() => handleToggleText('address')}>
          <ion-icon name="location"></ion-icon>
          {visibleText['address'] && <p>{shopData.shop.shopAddress}</p>}
        </IconWrapper>
      </DataFloat>
      <Share shopData={shopData} ref4={ref4} />
    </Header>
  );
}

ShopHeader.propTypes = {
  shopData: PropTypes.object.isRequired,
  isOwner: PropTypes.bool.isRequired,
  ref2: PropTypes.object.isRequired,
  ref4: PropTypes.object.isRequired,
};

export default ShopHeader;
