import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Loader from '../../components/LoadButton';
import useUserLocation from '../../utils/CustomHook/useUserLocation';
import { loadGoogleMaps } from '../../utils/googleMaps';
import Header from './Header';
import SearchBar from './SearchBar';
import TourWrapper from '../../components/TourWrapper';

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Styled components
const LoadContainer = styled.div`
  width: 100%;
  height: 40dvh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageContainer = styled.div`
  min-height: 100vh;
  padding: 20px;
`;

const ShopContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const ShopItem = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(10deg, #0000008f, transparent);
  height: 300px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  animation: ${slideUp} 1.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

    h3 {
      color: grey;
    }
  }

  h3 {
    color: #fff;
    margin: 0;
    padding: 10px 0;
  }

  p {
    text-align: center;
    font-size: 14px;
    color: #fff;
    margin: 10px 0;
  }
`;

const ShopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const ShopMenuButton = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  border: 2px solid #8e8e8e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background: #7c7c7c78;
  cursor: pointer;
`;

const Location = styled.div`
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #fff;
  background: #e9e9e9;
  position: fixed;
  top: 40px;
  right: 5px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ion-icon {
    color: #0d86cc;
  }

  @media (max-width: 768px) {
    top: 20px;
    right: 10px;
  }
`;

const FloatingInput = styled.input`
  position: fixed;
  top: 80px;
  right: 5px;
  z-index: 2;
  padding: 5px 15px;
  border: 2px solid #ccc;
  border-radius: 20px;
  width: 300px;
  outline: none;

  @media (max-width: 768px) {
    top: 60px;
    right: 10px;
    width: 180px;
  }
`;

const ShowMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px 25px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  background-color: #000;
  color: white;
  transition: background-color 0.3s;

  &:hover {
    background-color: #484848;
  }

  @media (max-width: 768px) {
    padding: 8px 20px;
    font-size: 14px;
  }
`;

const NoResultsMessage = styled.p`
  text-align: center;
  font-size: 18px;
  color: grey;
  margin-top: 20px;
`;

function Marketplace() {
  const [shops, setShops] = useState([]);
  const [filteredShops, setFilteredShops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleShops, setVisibleShops] = useState(10);
  const [newLocation, setNewLocation] = useState('Chargement...');
  const [showInput, setShowInput] = useState(false);
  const [address, setAddress] = useState('');
  const inputRef = useRef(null);
  const { location, coords, country, cities } = useUserLocation();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shop`);
        const jsonData = await response.json();
        setShops(jsonData.shopList);

        if (location !== 'Chargement...') {
          const filtered = jsonData.shopList.filter(shop => shop.shopAddress && shop.shopAddress.toLowerCase().includes(location.toLowerCase()));
          setFilteredShops(filtered.length > 0 ? filtered : jsonData.shopList);
        } else {
          setFilteredShops(jsonData.shopList);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    fetchData();
  }, [location]);

  useEffect(() => {
    const initAutocomplete = async () => {
      const google = await loadGoogleMaps();
      if (showInput && inputRef.current && google && google.maps) {
        const autocomplete = new google.maps.places.Autocomplete(inputRef.current);
        autocomplete.setFields(['address_components', 'formatted_address']);
        autocomplete.setTypes(['(cities)']);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place && place.formatted_address) {
            const addressParts = place.formatted_address.split(',');
            const city = addressParts[0].trim();
            setAddress(place.formatted_address);
            setNewLocation(city);
            setShowInput(false);
          }
        });
      }
    };

    initAutocomplete();
  }, [showInput]);

  useEffect(() => {
    if (newLocation !== 'Chargement...') {
      const filtered = shops.filter(shop => shop.shopAddress && shop.shopAddress.toLowerCase().includes(newLocation.toLowerCase()));
      setFilteredShops(filtered.length > 0 ? filtered : shops);
    }
  }, [newLocation, shops]);

  const handleLocationClick = () => {
    setShowInput(!showInput);
  };

  const handleShowMore = () => {
    setVisibleShops(prevVisibleShops => prevVisibleShops + 10);
  };

  const createMenu = (shopId) => ({
    items: [
      {
        key: '1',
        label: <Link to={`/shop/${shopId}`}>Voir le shop</Link>,
      },
      {
        key: '2',
        label: <Link to={`/shop/${shopId}`}>Contacter</Link>,
      },
      // {
      //   key: '3',
      //   label: <Link to={`/wishlist/${shopId}`}>Ajouter à la wishList</Link>,
      // },
    ],
  });

  const handleSearch = (query) => {
    const filtered = shops.filter(shop => {
      const shopName = shop.shopName || '';
      const businessCategory = shop.businessCategory || [];
      const shopAddress = shop.shopAddress || '';

      return (
        shopName.toLowerCase().includes(query.toLowerCase()) ||
        businessCategory.some(category => category.toLowerCase().includes(query.toLowerCase())) ||
        shopAddress.toLowerCase().includes(query.toLowerCase())
      );
    });
    setFilteredShops(filtered);
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const steps = [
    {
      title: 'Comment acceder à un shop',
      description: "Cliquez sur le menu en bas du poster d'un shop puis, selectionnez 'Voir ce shop' pour acceder directement à un shop.",
      target: () => ref1.current,
      mask: {
        style: {
          boxShadow: 'inset 0 0 15px #fff',
        },
        color: 'rgba(35, 35, 35, 0.808)',
      },
    },

    {
      title: 'Voir les boutiques de votre region locale.',
      description: "Cliquez sur la ville | region actuellement affichée, recherchez une ville et selectionnez la pour afficher les shops de cette ville | region.",
      target: () => ref2.current,
      mask: {
        style: {
          boxShadow: 'inset 0 0 15px #fff',
        },
        color: 'rgba(0, 0, 0, 0.808)',
      },
    },

    {
      title: 'Rechercher un shop en particulier',
      description: "Utilisez la barre de recherche pour rechercher un shop en particulier, vous pouvez obtenir plusieurs resultats en fonction de la categorie ou de la region | ville.",
      target: () => ref3.current,
      mask: {
        style: {
          boxShadow: 'inset 0 0 15px #fff',
        },
        color: 'rgba(0, 0, 0, 0.808)',
      },
    },

  ];

  return (
    <div>
      <PageContainer>
        <Location onClick={handleLocationClick} ref={ref2}>
          <ion-icon name="location-sharp"></ion-icon>
          <h6>{newLocation === 'Chargement...' ? location : newLocation}</h6>
        </Location>
        {showInput && (
          <FloatingInput ref={inputRef} type="text" placeholder="Saisir une adresse" />
        )}

        <Header />
        <SearchBar onSearch={handleSearch} ref3={ref3} />

        {loading ? (
          <LoadContainer>
            <Loader
              height="35px"
              width="35px"
              $borderColor="#2d2d2d"
              $background="transparent"
            />
          </LoadContainer>
        ) : (
          <div>
            {filteredShops.length > 0 ? (
              <>
                <ShopContainer>
                  {filteredShops.slice(0, visibleShops).map(shop => {
                    const shopLogo = shop.logo ? shop.logo : 'https://st4.depositphotos.com/20435048/23481/v/450/depositphotos_234811926-stock-illustration-lets-shopping-logo-design-template.jpg';
                    return (
                      <ShopItem key={shop._id}>
                        <ShopImage
                          src={shopLogo}
                          alt={shop.shopName}
                        />
                        <h3>{shop.shopName}</h3>
                        <p>{shop.businessCategory?.join(', ')}</p>
                        <Dropdown
                          menu={createMenu(shop._id)}
                          placement="topLeft"
                          arrow
                          trigger={['click']}
                        >
                          <ShopMenuButton ref={ref1}>
                            <EllipsisOutlined />
                          </ShopMenuButton>
                        </Dropdown>
                      </ShopItem>
                    );
                  })}
                </ShopContainer>

                {filteredShops.length > visibleShops && (
                  <ShowMoreButton onClick={handleShowMore}>Voir plus</ShowMoreButton>
                )}
              </>
            ) : (
              <NoResultsMessage>Aucun résultat trouvé.</NoResultsMessage>
            )}

            <TourWrapper
              // condition={isOwner}
              steps={steps}
              localStorageKey="hasVisitedShop"
            />
          </div>
        )}
      </PageContainer>
      <Footer />
    </div>
  );
}


export default Marketplace;
