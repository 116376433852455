// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// Optionnel : Importez d'autres services Firebase si nécessaire
// import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDxyvy08ZL9Qk6MfGKaj8g-bswpYyaOFTc",
  authDomain: "safaridew-app.firebaseapp.com",
  projectId: "safaridew-app",
  storageBucket: "safaridew-app.appspot.com",
  messagingSenderId: "16035854518",
  appId: "1:16035854518:web:1f74f6b4d0773fb2f28e17",
  measurementId: "G-1NTP31Q6TR" // Optionnel
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

// Optionnel : Initialisez d'autres services Firebase
// const analytics = getAnalytics(app);

export { messaging };





// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDxyvy08ZL9Qk6MfGKaj8g-bswpYyaOFTc",
//   authDomain: "safaridew-app.firebaseapp.com",
//   projectId: "safaridew-app",
//   storageBucket: "safaridew-app.appspot.com",
//   messagingSenderId: "16035854518",
//   appId: "1:16035854518:web:1f74f6b4d0773fb2f28e17",
//   measurementId: "G-1NTP31Q6TR"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);