import React from 'react';
import styled, { keyframes } from 'styled-components';

// Import des logos des marques
import aliexpress from '../../assets/trusted_brand/aliexpress-logo.svg';
import google from '../../assets/trusted_brand/google-color.svg';
import cinetpay from '../../assets/trusted_brand/logo-cinetpay.webp';
import mercedes from '../../assets/trusted_brand/mercedes-benz-logo.svg';
import nike from '../../assets/trusted_brand/nike-logo.svg';
import playStation from '../../assets/trusted_brand/play-station-logo.svg';
import toyota from '../../assets/trusted_brand/toyota-logo-color.svg';
import visaLogo from '../../assets/trusted_brand/visa-logo-color.svg';
import zara from '../../assets/trusted_brand/zara-logo.svg';
import zomato from '../../assets/trusted_brand/zomato-logo.svg';

// Styles pour le carousel
const CarouselContainer = styled.div`
    overflow: hidden;
    width: 100%;
    background-image: radial-gradient(#cecece 1px, transparent 1px);
    background-size: 22px 22px;
    background-color: #ffffff;
`;

const scroll = keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
`;

const CarouselContent = styled.div`
    display: flex;
    animation: ${scroll} 20s linear infinite;
`;

const CarouselImage = styled.img`
    width: 100px;
    height: auto;
    margin: 25px;
    margin-bottom: 50px;
    object-fit: contain;

    @media screen and (max-width: 768px) {
        width: 60px;
    }
`;

const images = [
    aliexpress,
    google,
    cinetpay,
    mercedes,
    nike,
    playStation,
    toyota,
    visaLogo,
    zara,
    zomato,
];

function TrustedBrandCarousel() {
    return (
        <CarouselContainer>
            <CarouselContent>
                {images.concat(images).map((image, index) => (
                    <CarouselImage key={index} src={image} alt={`Brand ${index}`} />
                ))}
            </CarouselContent>
        </CarouselContainer>
    );
}

export default TrustedBrandCarousel;
