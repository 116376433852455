import styled, { keyframes } from 'styled-components';
import { useState, useEffect, useContext } from 'react';
import Loader from '../LoadButton';
import { Select, message } from 'antd';
import './style.css';
import countries from '../Countries';
import { useAuthModal } from '../../utils/Context/AuthModal';

import { getCookies, checkAuthentication } from '../../utils/Cookies/getCookie';
import { setCookie } from '../../utils/Cookies/setCookie';

const { Option } = Select;
const rotateIn = keyframes`
    from {
        transform: rotateY(90deg);
    }
    to {
        transform: rotateY(0deg);
    }
`;

const rotateOut = keyframes`
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(-90deg);
    }
`;

const AuthContainer = styled.div`
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2d2;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    /* display: none; */
    /* display: ${(props) => (props.$showAuthModal ? 'flex' : 'none')}; */
    transition: 0.5s;
    transform: ${(props) => (props.$showAuthModal ? 'translateX(0)' : 'translateX(-100%)')};
`;

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 1000px;
    max-width: 90%;
    position: relative;
`;

const Form = styled.form`
    display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%; /* Modifiez cette ligne */
    animation: ${({ $visible }) => ($visible ? rotateIn : rotateOut)} 0.5s ease forwards;
    width: 100% !important;

    h1 {
        padding: 10px 0;
    }

    button {
        padding: 15px;
        width: 100%;
        border-radius: 25px;
        background: #fff;
        border: 1px solid #000;
        color: #000;
        cursor: pointer;
        font-size: 15px;
        transition: 0.3s;

        &:hover {
            background: #000;
            color: #fff;
        }
    }
`;

const InputContainer = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 20px;
`;

const CustomParagraph = styled.p`
    /* padding: 40px; */
    color: #b7b7b7 !important;
    font-size: 13px !important;
    cursor: pointer;

    span {
        font-weight: 700;
        color: #007bff !important;
    }
`;

const Input = styled.input`
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    transition: all 0.3s ease;

    &:focus {
        border-color: #007bff;
    }

    &::placeholder {
        color: #ccc;
    }
`;

const PasswordToggle = styled.div`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 20px;
    transition: 0.3s;

    &:hover {
        transform: translateY(-50%) scale(1.1);
    }
`;

const CloseModal = styled.div`
    /* padding: 5px; */
    position: absolute;
    z-index: 3;
    right: 10px;
    top: 10px;
    border: 2px solid #e6e6e6;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* font-weight: 700; */
    color: grey;
    font-size: 13px;
`;

function Auth({ isAuthenticated, setIsAuthenticated }) {
    // ============ FORM STATES ==============
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('+243');
    const [whatsapp, setWhatsapp] = useState(''); // Numéro Whatsapp complet
    // const [showAuthModal, setshowAuthModal] = useState(false);

    const { authModal, setAuthModal } = useAuthModal();

    useEffect(() => {
        if (authModal) {
            setAuthModal(true);
        }
    }, [authModal]);

    // const toggleAuthModal = () => {
    //     setAuthModal(!authModal);
    //     console.log(authModal);
    // };

    useEffect(() => {
        checkAuthentication(setIsAuthenticated);
    }, []);

    // setshowAuthModal(true);

    function closeModal() {
        setAuthModal(false);
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const validateEmail = (email) => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const validateWhatsapp = (whatsapp) => {
        const re = /^\+[0-9]{1,3}\s?[0-9]{1,4}\s?[0-9]{1,4}\s?[0-9]{1,4}\s?[0-9]{1,4}$/;
        return re.test(String(whatsapp));
    };

    // ====================== SUBMIT SIGNUP FORM ====================
    const [loading, setLoading] = useState(false);

    const handleSignUpSubmit = (e) => {
        e.preventDefault();

        if (!email || !password || !whatsapp) {
            message.error("Veuillez remplir tous les champs");
            return;
        }

        if (!validateEmail(email)) {
            message.error("Email invalide");
            return;
        }

        if (!validateWhatsapp(whatsapp)) {
            message.error("Numéro Whatsapp invalide");
            return;
        }

        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('whatsapp', whatsapp);

        // for (let pair of formData.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }

        (async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json', // Utiliser application/json pour le corps de la requête
                    },
                    body: JSON.stringify({ email, password, whatsapp }),
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la requête');
                } else {
                    message.success("Inscription réussie !");
                }

                const responseData = await response.json();
                // console.log(responseData);

                if (responseData.token) {
                    const AuthAccess = {
                        userId: responseData.userId,
                        AuthToken: responseData.token,
                    };

                    const expirationDate = new Date(); // Crée une nouvelle date
                    expirationDate.setTime(expirationDate.getTime() + 10 * 1000 * 10); // Ajoute 10 secondes à la date actuelle

                    // Convertit l'objet en chaîne de caractères JSON
                    const authAccessString = JSON.stringify(AuthAccess);

                    // Définit le cookie avec une expiration de 10 secondes
                    // document.cookie = `AuthCookies=${authAccessString}; expires=${expirationDate.toUTCString()}; path=/`;
                    setCookie('AuthCookies', authAccessString, 21600);
                    // setAuthModal(false);
                }
            } catch (error) {
                message.error("Erreur lors d'inscription");
            } finally {
                setLoading(false);
            }
        })();
    };

    const handleSignInSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            message.error("Veuillez remplir tous les champs");
            return;
        }

        if (!validateEmail(email)) {
            message.error("Email invalid");
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                message.error("Données incorrect, veuillez réessayer !");
            } else {
                message.success("connexion reussie !");
            }

            const responseData = await response.json();
            // console.log(responseData);

            if (responseData.token) {
                const AuthAccess = {
                    userId: responseData.userId,
                    AuthToken: responseData.token,
                };

                setCookie('AuthCookies', responseData.token, 6000); //Time in secondes
                setCookie('userId', responseData.userId, 6000);

                setIsAuthenticated(true);
            }
        } catch (error) {
            // console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSwitchForm = () => {
        setIsSignUp(!isSignUp);
    };

    // Mettre à jour le numéro Whatsapp complet à chaque changement du code de pays ou du numéro de téléphone
    const handleWhatsappChange = (e) => {
        const newWhatsapp = selectedCountry + e.target.value;
        setWhatsapp(newWhatsapp);
        // console.log(newWhatsapp);
    };

    const handleCountryCodeChange = (value) => {
        setSelectedCountry(value);
        setWhatsapp((prevWhatsapp) => value + prevWhatsapp.substr(selectedCountry.length));
    };

    return (
        !isAuthenticated && (
            <AuthContainer $showAuthModal={authModal}>
                <FormContainer>
                    <Form onSubmit={handleSignUpSubmit} $visible={isSignUp}>
                        <CloseModal onClick={closeModal}>&times;</CloseModal>
                        <h1>Créer un compte</h1>
                        <InputContainer>
                            <Input
                                name="email"
                                type="email"
                                placeholder="Entrez votre mail"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </InputContainer>
                        <InputContainer className="whatsapp-input">
                            {/* Select pour le code du pays */}
                            <Select
                                name="whatsapp_"
                                className="my-select"
                                style={{ width: 200 }}
                                value={selectedCountry}
                                // defaultValue="+1"
                                onChange={handleCountryCodeChange}
                                optionLabelProp="label"
                            >
                                {countries.map((country) => (
                                    <Option
                                        key={country.code}
                                        value={country.code}
                                        label={country.code}
                                    >
                                        <img
                                            src={country.flag}
                                            alt={`${country.name} flag`}
                                            style={{
                                                width: '20px',
                                                marginRight: '8px',
                                            }}
                                        />
                                        {`${country.name} ${country.code}`}
                                    </Option>
                                ))}
                            </Select>
                            {/* Champ de texte pour le numéro de téléphone */}
                            <input
                                name="whatsapp"
                                type="tel"
                                placeholder="Votre numéro (Whatsapp)"
                                value={whatsapp.substr(selectedCountry.length)} // Afficher uniquement le numéro de téléphone sans le code de pays
                                onChange={handleWhatsappChange}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Input
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Définir un mot de passe"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                            <PasswordToggle onClick={togglePasswordVisibility}>
                                <ion-icon
                                    name={showPassword ? 'eye-off-outline' : 'eye-outline'}
                                ></ion-icon>
                            </PasswordToggle>
                        </InputContainer>
                        <button type="submit"> {loading ? <Loader /> : 'Créer un compte'} </button>
                        <CustomParagraph onClick={handleSwitchForm}>
                            {' '}
                            Vous avez un compte ? <span> Se connecter</span>
                        </CustomParagraph>
                    </Form>
                    <Form onSubmit={handleSignInSubmit} $visible={!isSignUp}>
                        <CloseModal onClick={closeModal}>&times;</CloseModal>
                        <h1>Se connecter</h1>
                        <InputContainer>
                            <Input
                                type="email"
                                placeholder="Entrez votre mail"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </InputContainer>
                        <InputContainer>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Entrez votre mot de passe"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                            <PasswordToggle onClick={togglePasswordVisibility}>
                                <ion-icon
                                    name={showPassword ? 'eye-off-outline' : 'eye-outline'}
                                ></ion-icon>
                            </PasswordToggle>
                        </InputContainer>
                        <button type="submit">{loading ? <Loader /> : 'Se connecter '}</button>
                        <CustomParagraph onClick={handleSwitchForm}>
                            Vous n'avez pas de compte? <span> S'inscrire</span>
                        </CustomParagraph>
                    </Form>
                </FormContainer>
            </AuthContainer>
        )
    );
}

export default Auth;
