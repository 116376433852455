export function getCookies() {
    const cookieString = document.cookie;
    const cookies = {};
    cookieString.split(';').forEach((cookie) => {
        const [name, value] = cookie.trim().split('=');
        cookies[name] = decodeURIComponent(value);
    });
    return cookies;
}

export function checkAuthentication(setIsAuthenticated) {
    const allCookies = getCookies();
    const authCookies = allCookies.AuthCookies;
    // console.log(`Cookies Actuelles : ${authCookies}`);
    if (authCookies) {
        setIsAuthenticated(true);
    }
}

export function getToken() {
    const allCookies = getCookies();
    const authCookies = allCookies.AuthCookies;
    return authCookies;
}
