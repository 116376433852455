import React, { useRef } from 'react';
import styled from 'styled-components';
import Select from 'antd/lib/select';
import Loader from '../../LoadButton';
import options from '../../Categories';

const Formulaire = styled.form`
    padding: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;

    input {
        outline: none;
        border: 1px solid #fff;
        border-radius: 20px;
        background: transparent;
        width: 100%;
        margin: 10px 0;
        font-size: 15px;
        resize: none;
        transition: 0.3s;
        font-weight: 600;
        padding: 15px;
        border-radius: 25px;

        &:focus {
            box-shadow: 0 0 15px 0px #ffffff;
            background: #f4f4f46b;
        }

        &::placeholder {
            font-weight: 400;
            color: #fff;
        }
    }

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;

const ButtonDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    left: 0;
    margin-top: 10px;

    button {
        padding: 15px 50px;
        border-radius: 25px;
        margin-top: 5px;
        gap: 40px;
        font-size: 15px;
        outline: none;
        border: 1px solid #fff;
        width: 100%;
        background: #fff;
        font-weight: 600;
        color: #000;
        cursor: pointer;
    }
`;

const Step3 = ({ formData, handleInputChange, handleSubmit, loading, selectedOptions, handleSelectChange, containerRef, inputRef, selectedCoordinates, coords }) => {
    return (
      <Formulaire onSubmit={handleSubmit}>
        <input
          type="text"
          name="shopAddress"
          value={formData.shopAddress}
          onChange={handleInputChange}
          placeholder="Votre adresse"
          ref={inputRef}
        />
  
        <input
          type="hidden"
          name="shopCoordsBack"
          value={coords ? `${coords.latitude}, ${coords.longitude}` : ''}
          readOnly
        />
  
        <input
          type="hidden"
          name="selectedAddressCoordinates"
          value={
            selectedCoordinates
              ? `${selectedCoordinates.lat}, ${selectedCoordinates.lng}`
              : ''
          }
          readOnly
        />
        <Select
          name="businessCategory"
          mode="tags"
          style={{ width: '100%', padding: '10px' }}
          onChange={handleSelectChange}
          tokenSeparators={[',']}
          value={selectedOptions}
          options={options.map((option) => ({ value: option, label: option }))}
          placeholder="Catégorie de vos articles "
          getPopupContainer={() => containerRef.current}
          dropdownStyle={{ maxHeight: 200, overflow: 'auto' }}
        />
  
        <ButtonDiv onClick={(e) => handleSubmit(e)}>
          <button type="submit">
            {loading ? (
              <Loader
                height="20px"
                width="20px"
                $borderColor="#2d2d2d"
                $background="transparent"
              />
            ) : (
              'Créer ma boutique'
            )}
          </button>
        </ButtonDiv>
      </Formulaire>
    );
  };
  
  export default Step3;