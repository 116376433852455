const countries = [
    { name: 'Afghanistan', code: '+93', flag: 'https://flagcdn.com/af.svg' },
    { name: 'Albania', code: '+355', flag: 'https://flagcdn.com/al.svg' },
    { name: 'Algeria', code: '+213', flag: 'https://flagcdn.com/dz.svg' },
    { name: 'Andorra', code: '+376', flag: 'https://flagcdn.com/ad.svg' },
    { name: 'Angola', code: '+244', flag: 'https://flagcdn.com/ao.svg' },
    { name: 'Antigua and Barbuda', code: '+1268', flag: 'https://flagcdn.com/ag.svg' },
    { name: 'Argentina', code: '+54', flag: 'https://flagcdn.com/ar.svg' },
    { name: 'Armenia', code: '+374', flag: 'https://flagcdn.com/am.svg' },
    { name: 'Australia', code: '+61', flag: 'https://flagcdn.com/au.svg' },
    { name: 'Austria', code: '+43', flag: 'https://flagcdn.com/at.svg' },
    { name: 'Azerbaijan', code: '+994', flag: 'https://flagcdn.com/az.svg' },
    { name: 'Bahamas', code: '+1242', flag: 'https://flagcdn.com/bs.svg' },
    { name: 'Bahrain', code: '+973', flag: 'https://flagcdn.com/bh.svg' },
    { name: 'Bangladesh', code: '+880', flag: 'https://flagcdn.com/bd.svg' },
    { name: 'Barbados', code: '+1246', flag: 'https://flagcdn.com/bb.svg' },
    { name: 'Belarus', code: '+375', flag: 'https://flagcdn.com/by.svg' },
    { name: 'Belgium', code: '+32', flag: 'https://flagcdn.com/be.svg' },
    { name: 'Belize', code: '+501', flag: 'https://flagcdn.com/bz.svg' },
    { name: 'Benin', code: '+229', flag: 'https://flagcdn.com/bj.svg' },
    { name: 'Bhutan', code: '+975', flag: 'https://flagcdn.com/bt.svg' },
    { name: 'Bolivia', code: '+591', flag: 'https://flagcdn.com/bo.svg' },
    { name: 'Bosnia and Herzegovina', code: '+387', flag: 'https://flagcdn.com/ba.svg' },
    { name: 'Botswana', code: '+267', flag: 'https://flagcdn.com/bw.svg' },
    { name: 'Brazil', code: '+55', flag: 'https://flagcdn.com/br.svg' },
    { name: 'Brunei', code: '+673', flag: 'https://flagcdn.com/bn.svg' },
    { name: 'Bulgaria', code: '+359', flag: 'https://flagcdn.com/bg.svg' },
    { name: 'Burkina Faso', code: '+226', flag: 'https://flagcdn.com/bf.svg' },
    { name: 'Burundi', code: '+257', flag: 'https://flagcdn.com/bi.svg' },
    { name: 'Cambodia', code: '+855', flag: 'https://flagcdn.com/kh.svg' },
    { name: 'Cameroon', code: '+237', flag: 'https://flagcdn.com/cm.svg' },
    { name: 'Canada', code: '+1', flag: 'https://flagcdn.com/ca.svg' },
    { name: 'Cape Verde', code: '+238', flag: 'https://flagcdn.com/cv.svg' },
    { name: 'Central African Republic', code: '+236', flag: 'https://flagcdn.com/cf.svg' },
    { name: 'Chad', code: '+235', flag: 'https://flagcdn.com/td.svg' },
    { name: 'Chile', code: '+56', flag: 'https://flagcdn.com/cl.svg' },
    { name: 'China', code: '+86', flag: 'https://flagcdn.com/cn.svg' },
    { name: 'Colombia', code: '+57', flag: 'https://flagcdn.com/co.svg' },
    { name: 'Comoros', code: '+269', flag: 'https://flagcdn.com/km.svg' },
    { name: 'Congo', code: '+242', flag: 'https://flagcdn.com/cg.svg' },
    { name: 'Congo Kinshasa', code: '+243', flag: 'https://flagcdn.com/cd.svg' },
    { name: "Côte d'Ivoire", code: '+225', flag: 'https://flagcdn.com/ci.svg' },
    { name: 'Croatia', code: '+385', flag: 'https://flagcdn.com/hr.svg' },
    { name: 'Cuba', code: '+53', flag: 'https://flagcdn.com/cu.svg' },
    { name: 'Cyprus', code: '+357', flag: 'https://flagcdn.com/cy.svg' },
    { name: 'Czech Republic', code: '+420', flag: 'https://flagcdn.com/cz.svg' },
    { name: 'Denmark', code: '+45', flag: 'https://flagcdn.com/dk.svg' },
    { name: 'Djibouti', code: '+253', flag: 'https://flagcdn.com/dj.svg' },
    { name: 'Dominica', code: '+1767', flag: 'https://flagcdn.com/dm.svg' },
    { name: 'Dominican Republic', code: '+1809', flag: 'https://flagcdn.com/do.svg' },
    { name: 'Ecuador', code: '+593', flag: 'https://flagcdn.com/ec.svg' },
    { name: 'Egypt', code: '+20', flag: 'https://flagcdn.com/eg.svg' },
    { name: 'El Salvador', code: '+503', flag: 'https://flagcdn.com/sv.svg' },
    { name: 'Equatorial Guinea', code: '+240', flag: 'https://flagcdn.com/gq.svg' },
    { name: 'Eritrea', code: '+291', flag: 'https://flagcdn.com/er.svg' },
    { name: 'Estonia', code: '+372', flag: 'https://flagcdn.com/ee.svg' },
    { name: 'Ethiopia', code: '+251', flag: 'https://flagcdn.com/et.svg' },
    { name: 'Fiji', code: '+679', flag: 'https://flagcdn.com/fj.svg' },
    { name: 'Finland', code: '+358', flag: 'https://flagcdn.com/fi.svg' },
    { name: 'France', code: '+33', flag: 'https://flagcdn.com/fr.svg' },
    { name: 'Gabon', code: '+241', flag: 'https://flagcdn.com/ga.svg' },
    { name: 'Gambia', code: '+220', flag: 'https://flagcdn.com/gm.svg' },
    { name: 'Georgia', code: '+995', flag: 'https://flagcdn.com/ge.svg' },
    { name: 'Germany', code: '+49', flag: 'https://flagcdn.com/de.svg' },
    { name: 'Ghana', code: '+233', flag: 'https://flagcdn.com/gh.svg' },
    { name: 'Greece', code: '+30', flag: 'https://flagcdn.com/gr.svg' },
    { name: 'Grenada', code: '+1473', flag: 'https://flagcdn.com/gd.svg' },
    { name: 'Guatemala', code: '+502', flag: 'https://flagcdn.com/gt.svg' },
    { name: 'Guinea', code: '+224', flag: 'https://flagcdn.com/gn.svg' },
    { name: 'Guinea-Bissau', code: '+245', flag: 'https://flagcdn.com/gw.svg' },
    { name: 'Guyana', code: '+592', flag: 'https://flagcdn.com/gy.svg' },
    { name: 'Haiti', code: '+509', flag: 'https://flagcdn.com/ht.svg' },
    { name: 'Honduras', code: '+504', flag: 'https://flagcdn.com/hn.svg' },
    { name: 'Hungary', code: '+36', flag: 'https://flagcdn.com/hu.svg' },
    { name: 'Iceland', code: '+354', flag: 'https://flagcdn.com/is.svg' },
    { name: 'India', code: '+91', flag: 'https://flagcdn.com/in.svg' },
    { name: 'Indonesia', code: '+62', flag: 'https://flagcdn.com/id.svg' },
    { name: 'Iran', code: '+98', flag: 'https://flagcdn.com/ir.svg' },
    { name: 'Iraq', code: '+964', flag: 'https://flagcdn.com/iq.svg' },
    { name: 'Ireland', code: '+353', flag: 'https://flagcdn.com/ie.svg' },
    { name: 'Israel', code: '+972', flag: 'https://flagcdn.com/il.svg' },
    { name: 'Italy', code: '+39', flag: 'https://flagcdn.com/it.svg' },
    { name: 'Jamaica', code: '+1876', flag: 'https://flagcdn.com/jm.svg' },
    { name: 'Japan', code: '+81', flag: 'https://flagcdn.com/jp.svg' },
    { name: 'Jordan', code: '+962', flag: 'https://flagcdn.com/jo.svg' },
    { name: 'Kazakhstan', code: '+77', flag: 'https://flagcdn.com/kz.svg' },
    { name: 'Kenya', code: '+254', flag: 'https://flagcdn.com/ke.svg' },
    { name: 'Kiribati', code: '+686', flag: 'https://flagcdn.com/ki.svg' },
    { name: 'Korea, North', code: '+850', flag: 'https://flagcdn.com/kp.svg' },
    { name: 'Korea, South', code: '+82', flag: 'https://flagcdn.com/kr.svg' },
    { name: 'Kuwait', code: '+965', flag: 'https://flagcdn.com/kw.svg' },
    { name: 'Kyrgyzstan', code: '+996', flag: 'https://flagcdn.com/kg.svg' },
    { name: 'Laos', code: '+856', flag: 'https://flagcdn.com/la.svg' },
    { name: 'Latvia', code: '+371', flag: 'https://flagcdn.com/lv.svg' },
    { name: 'Lebanon', code: '+961', flag: 'https://flagcdn.com/lb.svg' },
    { name: 'Lesotho', code: '+266', flag: 'https://flagcdn.com/ls.svg' },
    { name: 'Liberia', code: '+231', flag: 'https://flagcdn.com/lr.svg' },
    { name: 'Libya', code: '+218', flag: 'https://flagcdn.com/ly.svg' },
    { name: 'Liechtenstein', code: '+423', flag: 'https://flagcdn.com/li.svg' },
    { name: 'Lithuania', code: '+370', flag: 'https://flagcdn.com/lt.svg' },
    { name: 'Luxembourg', code: '+352', flag: 'https://flagcdn.com/lu.svg' },
    { name: 'Madagascar', code: '+261', flag: 'https://flagcdn.com/mg.svg' },
    { name: 'Malawi', code: '+265', flag: 'https://flagcdn.com/mw.svg' },
    { name: 'Malaysia', code: '+60', flag: 'https://flagcdn.com/my.svg' },
    { name: 'Maldives', code: '+960', flag: 'https://flagcdn.com/mv.svg' },
    { name: 'Mali', code: '+223', flag: 'https://flagcdn.com/ml.svg' },
    { name: 'Malta', code: '+356', flag: 'https://flagcdn.com/mt.svg' },
    { name: 'Marshall Islands', code: '+692', flag: 'https://flagcdn.com/mh.svg' },
    { name: 'Mauritania', code: '+222', flag: 'https://flagcdn.com/mr.svg' },
    { name: 'Mauritius', code: '+230', flag: 'https://flagcdn.com/mu.svg' },
    { name: 'Mayotte', code: '+262', flag: 'https://flagcdn.com/yt.svg' },
    { name: 'Mexico', code: '+52', flag: 'https://flagcdn.com/mx.svg' },
    { name: 'Micronesia', code: '+691', flag: 'https://flagcdn.com/fm.svg' },
    { name: 'Moldova', code: '+373', flag: 'https://flagcdn.com/md.svg' },
    { name: 'Monaco', code: '+377', flag: 'https://flagcdn.com/mc.svg' },
    { name: 'Mongolia', code: '+976', flag: 'https://flagcdn.com/mn.svg' },
    { name: 'Montenegro', code: '+382', flag: 'https://flagcdn.com/me.svg' },
    { name: 'Montserrat', code: '+1664', flag: 'https://flagcdn.com/ms.svg' },
    { name: 'Morocco', code: '+212', flag: 'https://flagcdn.com/ma.svg' },
    { name: 'Mozambique', code: '+258', flag: 'https://flagcdn.com/mz.svg' },
    { name: 'Myanmar', code: '+95', flag: 'https://flagcdn.com/mm.svg' },
    { name: 'Namibia', code: '+264', flag: 'https://flagcdn.com/na.svg' },
    { name: 'Nauru', code: '+674', flag: 'https://flagcdn.com/nr.svg' },
    { name: 'Nepal', code: '+977', flag: 'https://flagcdn.com/np.svg' },
    { name: 'Netherlands', code: '+31', flag: 'https://flagcdn.com/nl.svg' },
    { name: 'New Zealand', code: '+64', flag: 'https://flagcdn.com/nz.svg' },
    { name: 'Nicaragua', code: '+505', flag: 'https://flagcdn.com/ni.svg' },
    { name: 'Niger', code: '+227', flag: 'https://flagcdn.com/ne.svg' },
    { name: 'Nigeria', code: '+234', flag: 'https://flagcdn.com/ng.svg' },
    { name: 'Niue', code: '+683', flag: 'https://flagcdn.com/nu.svg' },
    { name: 'Norfolk Island', code: '+672', flag: 'https://flagcdn.com/nf.svg' },
    { name: 'North Macedonia', code: '+389', flag: 'https://flagcdn.com/mk.svg' },
    { name: 'Norway', code: '+47', flag: 'https://flagcdn.com/no.svg' },
    { name: 'Oman', code: '+968', flag: 'https://flagcdn.com/om.svg' },
    { name: 'Pakistan', code: '+92', flag: 'https://flagcdn.com/pk.svg' },
    { name: 'Palau', code: '+680', flag: 'https://flagcdn.com/pw.svg' },
    { name: 'Palestine', code: '+970', flag: 'https://flagcdn.com/ps.svg' },
    { name: 'Panama', code: '+507', flag: 'https://flagcdn.com/pa.svg' },
    { name: 'Papua New Guinea', code: '+675', flag: 'https://flagcdn.com/pg.svg' },
    { name: 'Paraguay', code: '+595', flag: 'https://flagcdn.com/py.svg' },
    { name: 'Peru', code: '+51', flag: 'https://flagcdn.com/pe.svg' },
    { name: 'Philippines', code: '+63', flag: 'https://flagcdn.com/ph.svg' },
    { name: 'Poland', code: '+48', flag: 'https://flagcdn.com/pl.svg' },
    { name: 'Portugal', code: '+351', flag: 'https://flagcdn.com/pt.svg' },
    { name: 'Qatar', code: '+974', flag: 'https://flagcdn.com/qa.svg' },
    { name: 'Romania', code: '+40', flag: 'https://flagcdn.com/ro.svg' },
    { name: 'Russia', code: '+7', flag: 'https://flagcdn.com/ru.svg' },
    { name: 'Rwanda', code: '+250', flag: 'https://flagcdn.com/rw.svg' },
    { name: 'Saint Kitts and Nevis', code: '+1869', flag: 'https://flagcdn.com/kn.svg' },
    { name: 'Saint Lucia', code: '+1758', flag: 'https://flagcdn.com/lc.svg' },
    { name: 'Saint Vincent and the Grenadines', code: '+1784', flag: 'https://flagcdn.com/vc.svg' },
    { name: 'Samoa', code: '+685', flag: 'https://flagcdn.com/ws.svg' },
    { name: 'San Marino', code: '+378', flag: 'https://flagcdn.com/sm.svg' },
    { name: 'Sao Tome and Principe', code: '+239', flag: 'https://flagcdn.com/st.svg' },
    { name: 'Saudi Arabia', code: '+966', flag: 'https://flagcdn.com/sa.svg' },
    { name: 'Senegal', code: '+221', flag: 'https://flagcdn.com/sn.svg' },
    { name: 'Serbia', code: '+381', flag: 'https://flagcdn.com/rs.svg' },
    { name: 'Seychelles', code: '+248', flag: 'https://flagcdn.com/sc.svg' },
    { name: 'Sierra Leone', code: '+232', flag: 'https://flagcdn.com/sl.svg' },
    { name: 'Singapore', code: '+65', flag: 'https://flagcdn.com/sg.svg' },
    { name: 'Slovakia', code: '+421', flag: 'https://flagcdn.com/sk.svg' },
    { name: 'Slovenia', code: '+386', flag: 'https://flagcdn.com/si.svg' },
    { name: 'Solomon Islands', code: '+677', flag: 'https://flagcdn.com/sb.svg' },
    { name: 'Somalia', code: '+252', flag: 'https://flagcdn.com/so.svg' },
    { name: 'South Africa', code: '+27', flag: 'https://flagcdn.com/za.svg' },
    { name: 'South Sudan', code: '+211', flag: 'https://flagcdn.com/ss.svg' },
    { name: 'Spain', code: '+34', flag: 'https://flagcdn.com/es.svg' },
    { name: 'Sri Lanka', code: '+94', flag: 'https://flagcdn.com/lk.svg' },
    { name: 'Sudan', code: '+249', flag: 'https://flagcdn.com/sd.svg' },
    { name: 'Suriname', code: '+597', flag: 'https://flagcdn.com/sr.svg' },
    { name: 'Sweden', code: '+46', flag: 'https://flagcdn.com/se.svg' },
    { name: 'Switzerland', code: '+41', flag: 'https://flagcdn.com/ch.svg' },
    { name: 'Syria', code: '+963', flag: 'https://flagcdn.com/sy.svg' },
    { name: 'Taiwan', code: '+886', flag: 'https://flagcdn.com/tw.svg' },
    { name: 'Tajikistan', code: '+992', flag: 'https://flagcdn.com/tj.svg' },
    { name: 'Tanzania', code: '+255', flag: 'https://flagcdn.com/tz.svg' },
    { name: 'Thailand', code: '+66', flag: 'https://flagcdn.com/th.svg' },
    { name: 'Timor-Leste', code: '+670', flag: 'https://flagcdn.com/tl.svg' },
    { name: 'Togo', code: '+228', flag: 'https://flagcdn.com/tg.svg' },
    { name: 'Tonga', code: '+676', flag: 'https://flagcdn.com/to.svg' },
    { name: 'Trinidad and Tobago', code: '+1868', flag: 'https://flagcdn.com/tt.svg' },
    { name: 'Tunisia', code: '+216', flag: 'https://flagcdn.com/tn.svg' },
    { name: 'Turkey', code: '+90', flag: 'https://flagcdn.com/tr.svg' },
    { name: 'Turkmenistan', code: '+993', flag: 'https://flagcdn.com/tm.svg' },
    { name: 'Tuvalu', code: '+688', flag: 'https://flagcdn.com/tv.svg' },
    { name: 'Uganda', code: '+256', flag: 'https://flagcdn.com/ug.svg' },
    { name: 'Ukraine', code: '+380', flag: 'https://flagcdn.com/ua.svg' },
    { name: 'United Arab Emirates', code: '+971', flag: 'https://flagcdn.com/ae.svg' },
    { name: 'United Kingdom', code: '+44', flag: 'https://flagcdn.com/gb.svg' },
    { name: 'United States', code: '+1', flag: 'https://flagcdn.com/us.svg' },
    { name: 'Uruguay', code: '+598', flag: 'https://flagcdn.com/uy.svg' },
    { name: 'Uzbekistan', code: '+998', flag: 'https://flagcdn.com/uz.svg' },
    { name: 'Vanuatu', code: '+678', flag: 'https://flagcdn.com/vu.svg' },
    { name: 'Vatican City', code: '+379', flag: 'https://flagcdn.com/va.svg' },
    { name: 'Venezuela', code: '+58', flag: 'https://flagcdn.com/ve.svg' },
    { name: 'Vietnam', code: '+84', flag: 'https://flagcdn.com/vn.svg' },
    { name: 'Yemen', code: '+967', flag: 'https://flagcdn.com/ye.svg' },
    { name: 'Zambia', code: '+260', flag: 'https://flagcdn.com/zm.svg' },
    { name: 'Zimbabwe', code: '+263', flag: 'https://flagcdn.com/zw.svg' },
];

export default countries;
