import styled from "styled-components";
import { Modal, Input, Button, message } from "antd";
import { useState } from "react";
import { getToken } from "../../../../../../utils/Cookies/getCookie";

const Container = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
`;

const CustomButton = styled.button`
    padding: 10px 15px;
    border: 1px solid #000;
    color: #fff;
    background: #000;
    border-radius: 20px;
    cursor: pointer;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    &:hover {
        background-color: transparent;
        color: #000;

        ion-icon {
            transform: translateX(5px);
        }
    }

    ion-icon {
        font-size: 20px;
        transition: 0.3s;
    }
`;

const CustomInput = styled(Input)`
    padding: 10px 5px;
    border-radius: 10px;
`

const StyledModal = styled(Modal)`
    .ant-modal-content{
        border-radius: 20px !important;
    }

    .ant-btn{
        border-color: #000;
        border-radius: 20px;
        padding: 20px;

        &:hover, &:focus {
            border-color: #000 !important;
            background: #333 !important;
            color: #fff !important;
        }
    }

    .ant-btn-primary {
        background-color: #000;

        &.ant-btn-loading {
            background-color: #999;
            border-color: #999;
        }
    }

    .ant-btn-primary span {
        color: #fff;
    }

`;

function ActionOnArticle({ shopData, isOwner, article }) {
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editTitle, setEditTitle] = useState(article.title);
    const [editPrice, setEditPrice] = useState(article.price);
    const [editDescription, setEditDescription] = useState(article.description);
    const [isUpdating, setIsUpdating] = useState(false);
    const token = getToken(); // Remplacez par le vrai token

    const handleEdit = async () => {
        if (!isOwner) return;

        const formData = new FormData();
        formData.append('title', editTitle);
        formData.append('price', editPrice);
        formData.append('description', editDescription);
        setIsUpdating(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/articles/${article._id}`, {
                method: 'PUT',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (response.ok) {
                message.success('Article modifié avec succès');
                setEditModalVisible(false);

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                message.error(data.error || 'Échec de la modification de l\'article');
            }
        } catch (error) {
            message.error('Échec de la modification de l\'article');
        } finally {
            setIsUpdating(false);
        }
    };

    const handleDelete = async () => {
        if (!isOwner) return;

        setIsUpdating(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/articles/${article._id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (response.ok) {
                message.success('Article supprimé avec succès');

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                message.error(data.error || 'Échec de la suppression de l\'article');
            }
        } catch (error) {
            message.error('Échec de la suppression de l\'article');
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <Container>
            {isOwner && (
                <>
                    <CustomButton onClick={() => setEditModalVisible(true)}>
                        Modifier <ion-icon name="pencil-sharp"></ion-icon>
                    </CustomButton>
                    <CustomButton onClick={handleDelete}>
                        Supprimer <ion-icon name="trash-outline"></ion-icon>
                    </CustomButton>
                </>
            )}

            <StyledModal
                title="Modifier l'article"
                open={editModalVisible}
                onOk={handleEdit}
                onCancel={() => setEditModalVisible(false)}
                okText={isUpdating ? "En cours..." : "Enregistrer"}
                cancelText="Annuler"
                confirmLoading={isUpdating}
            >
                <CustomInput
                    placeholder="Titre"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />
                <CustomInput
                    placeholder="Prix"
                    value={editPrice}
                    onChange={(e) => setEditPrice(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />
                <Input.TextArea
                    placeholder="Description"
                    value={editDescription}
                    onChange={(e) => setEditDescription(e.target.value)}
                    rows={4}
                />
            </StyledModal>
        </Container>
    );
}

export default ActionOnArticle;
