import React from 'react';
import styled, { keyframes } from 'styled-components';
import Footer from '../../components/Footer';

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const float4 = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const float0 = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
  animation: ${fadeIn} 1.5s ease-in-out;
  text-align: center;
  padding: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Four = styled.h1`
  font-size: 6rem;
  color: #ff6f61;
  margin: 0 10px;
  animation: ${float4} 3s ease-in-out infinite;

  @media (max-width: 768px) {
    font-size: 5rem;
  }

  @media (max-width: 480px) {
    font-size: 5rem;
  }
`;

const Zero = styled.h1`
  font-size: 8rem;
  color: #ff6f61;
  margin: 0 10px;
  animation: ${float0} 3s ease-in-out infinite;

  @media (max-width: 768px) {
    font-size: 8rem;
  }

  @media (max-width: 480px) {
    font-size: 8rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-bottom: 20px;
  }
`;

const Button = styled.button`
  background-color: #606060;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 10px 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #161616;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 12px 25px;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    padding: 10px 20px;
  }
`;

function NotFound() {
  return (
    <div>
    <Container>
      <TitleContainer>
        <Four>4</Four>
        <Zero>0</Zero>
        <Four>4</Four>
      </TitleContainer>
      <Subtitle>Page non trouvée. La page que vous recherchez n'existe pas ou a été déplacée.</Subtitle>
      <Button onClick={() => window.location.href = '/'}>Retour à l'accueil</Button>
    </Container>
    <Footer />
    </div>
  );
}

export default NotFound;
