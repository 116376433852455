import React, { useEffect } from 'react';
import { useMeta } from './MetaContext';

const MetaUpdater = () => {
  const { metaData } = useMeta();

  useEffect(() => {
    // Met à jour le titre de la page
    document.title = metaData.title;

    // Met à jour l'icône de la page
    const link = document.querySelector("link[rel='icon']");
    if (link) {
      link.href = metaData.icon;
    }

    // console.log('Updated meta data:', metaData);
  }, [metaData]);

  return null;
};

export default MetaUpdater;
