import React from 'react';
import styled from 'styled-components';

const Formulaire = styled.form`
    padding: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;

    textarea {
        outline: none;
        border: 1px solid #fff;
        border-radius: 20px;
        background: transparent;
        width: 100%;
        margin: 10px 0;
        font-size: 15px;
        resize: none;
        transition: 0.3s;
        font-weight: 600;
        padding: 15px;
        border-radius: 25px;

        &:focus {
            box-shadow: 0 0 15px 0px #ffffff;
            background: #f4f4f46b;
        }

        &::placeholder {
            font-weight: 400;
            color: #fff;
        }
    }

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;

const ButtonDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    left: 0;
    margin-top: 10px;

    button {
        padding: 15px 50px;
        border-radius: 25px;
        margin-top: 5px;
        gap: 40px;
        font-size: 15px;
        outline: none;
        border: 1px solid #fff;
        width: 100%;
        background: #fff;
        font-weight: 600;
        color: #000;
        cursor: pointer;
    }
`;

function Step2({ formData, handleInputChange, handleNextStep, handlePrevStep }) {
    return (
        <Formulaire>
            <textarea
                name="shopDescription"
                value={formData.shopDescription}
                onChange={handleInputChange}
                cols="30"
                rows="4"
                placeholder="Décrivez votre boutique"
            ></textarea>
            <ButtonDiv className="TwoItems">
                <button type="button" onClick={handlePrevStep}>Précédent</button>
                <button type="button" onClick={handleNextStep}> Suivant </button>
            </ButtonDiv>
        </Formulaire>
    );
}

export default Step2;
