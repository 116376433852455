import React from 'react';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const SkeletonText = styled.div`
  font-size: 36px;
  font-weight: bold;
  position: relative;
  color: transparent;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  animation: ${loading} 1.5s infinite;

  @media screen and (max-width:768px) {
    font-size: 20px;
  }
`;

const ImageLoader = () => {
  return (
    <LoaderWrapper>
      <SkeletonText>elanga</SkeletonText>
    </LoaderWrapper>
  );
};

export default ImageLoader;
