const options = [
    'Alimentation',
    'Animaux',
    'Art et artisanat',
    'Beauté et soins personnels',
    'Bijouterie',
    'Brico et jardinage',
    'Communication',
    'Conseil',
    'Construction',
    'Développement logiciel',
    'Divertissement',
    'Électronique',
    'Épicerie',
    'Équipement sportif',
    'Formation et éducation',
    'Formation professionnelle',
    'Grossiste',
    'Habillement',
    'Jouets et jeux',
    'Livres et presse',
    'Maison et décoration',
    'Maintenance',
    'Matériel de bureau',
    'Matériel médical',
    'Mobilier',
    'Musique',
    'Nettoyage',
    'Photographie',
    'Réparation',
    'Santé',
    'Services de conception web',
    'Services de gestion',
    'Services de gestion des réseaux',
    'Services de marketing',
    'Services de restauration',
    'Services de support technique',
    'Services de traduction',
    'Services de transport',
    'Services de consultation',
    'Sécurité & Gardiennage',
    'Téléphones & Ordinateurs',
    'Véhicules',
    'Voyages',
    'Téléphonie',
    'Véhicules',
    'Voyages',
];

export default options;
