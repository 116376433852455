import styled, { keyframes } from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const spinAnimation = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

const Spin = styled.div`
    border: 2px solid transparent;
    border-right-color: ${(props) => props.$borderColor || '#383838'};
    border-top-style: dashed;
    border-top-color: ${(props) => props.$borderColor || '#383838'};
    border-left-style: dotted;
    border-left-color: ${(props) => props.$borderColor || '#383838'};
    width: ${(props) => props.width || '20px'};
    height: ${(props) => props.height || '20px'};
    border-radius: 50%;
    animation: ${spinAnimation} 0.8s infinite linear;
    background: ${(props) => props.$background || 'transparent'};
`;

function Loader({ height, width, $borderColor, $background }) {
    return (
        <Container>
            <Spin
                height={height}
                width={width}
                $borderColor={$borderColor}
                $background={$background}
            />
        </Container>
    );
}

export default Loader;
