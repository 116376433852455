import React, { useState } from 'react';
import { Modal, Form, Input, DatePicker, message, Button } from 'antd';
import styled from 'styled-components';
import { getToken } from '../../../../utils/Cookies/getCookie';
import moment from 'moment';

const CustomInput = styled(Input)`
    padding: 10px;
    border-radius: 10px;
    box-shadow: none;

    &:focus{
        box-shadow: none;
    }
`;

const StyledButton = styled(Button)`
    background-color: #000;
    border-color: #000;
    border-radius: 20px;
    padding: 20px;
    color: white;
    
    &:hover {
        background-color: transparent !important;
        color: #000 !important;
    }
    
    &:focus {
        background-color: #2e2e2e;
    }

    &.cancel{
        background: transparent;
        color: #c2c2c2;
        border-color: #c2c2c2;
    }
`;

const EditUserModal = ({ user, visible, onClose, onSave }) => {
  const token = getToken();
  const [isUpdating, setIsUpdating] = useState(false);
  const [form] = Form.useForm();

  const handleSave = async () => {
    setIsUpdating(true);

    try {
      const values = await form.validateFields();

      const response = await fetch(`http://localhost:3500/api/user/${user._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(values)
      });

      if (!response.ok) {
        const responseBody = await response.json();
        throw new Error(responseBody.error || "Echec de l'opération");
      }

      const updatedUser = await response.json();
      onSave(updatedUser);
      message.success('Modifié avec succès');
      onClose();
    } catch (error) {
      if (error.message.includes('email')) {
        message.error('Email erreur');
      } else if (error.message.includes('WhatsApp')) {
        message.error('Le numéro saisi est déjà utilisé par un autre compte');
      } else {
        message.error('Failed to save: ' + error.message);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Modal
      title="Modifier les informations du compte"
      open={visible}
      onCancel={onClose}
      footer={[
        <StyledButton key="cancel" onClick={onClose} className='cancel'>
          Annuler
        </StyledButton>,
        <StyledButton key="save" onClick={handleSave} loading={isUpdating} type="primary">
          {isUpdating ? 'En cours...' : 'Enregistrer'}
        </StyledButton>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          email: user.email,
          whatsapp: user.whatsapp,
          firstName: user.firstName,
          lastName: user.lastName,
          birthDate: user.birthDate ? moment(user.birthDate) : null
        }}
      >
        {/* <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Veuillez saisir votre email' }]}>
          <CustomInput />
        </Form.Item> */}
        <Form.Item label="WhatsApp" name="whatsapp" rules={[{ required: true, message: 'Veuillez saisir votre numéro whatsapp' }]}>
          <CustomInput />
        </Form.Item>
        <Form.Item label="Prénom" name="firstName" rules={[{ required: false, message: 'Veuillez saisir votre prénom' }]}>
          <CustomInput />
        </Form.Item>
        <Form.Item label="Nom de famille" name="lastName" rules={[{ required: false, message: 'Veuillez saisir votre nom de famille' }]}>
          <CustomInput />
        </Form.Item>
        <Form.Item label="Date de naissance" name="birthDate">
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditUserModal;
