import { useState, useEffect } from 'react';

const useUserLocation = () => {
    const [location, setLocation] = useState('Chargement...');
    const [country, setCountry] = useState('Inconnue');
    const [cities, setCities] = useState([]);
    const [coords, setCoords] = useState({ latitude: null, longitude: null }); // Initialize coords with null values

    const fetchLocationByLatLng = async (latitude, longitude) => {
        try {
            const response = await fetch(`https://geocode.xyz/${latitude},${longitude}?geoit=json`);
            const data = await response.json();
            const userCity = data.city || 'Inconnue';
            const userCountry = data.country || 'Inconnue';
            setLocation(userCity);
            setCountry(userCountry);
            setCoords({ latitude, longitude }); // Update coords with latitude and longitude
        } catch (error) {
            console.error('Error fetching location:', error);
            setLocation('Inconnue');
            setCountry('Inconnue');
            setCities([]);
        }
    };

    const fetchLocationByIP = async () => {
        try {
            const ipInfoKey = process.env.REACT_APP_IP_INFO_TOKEN;
            const response = await fetch(`https://ipinfo.io/json?token=${ipInfoKey}`);
            const data = await response.json();
            const userCity = data.city || 'Inconnue';
            const userCountry = data.country || 'Inconnue';
            setLocation(userCity);
            setCountry(userCountry);

            // Extract latitude and longitude from ipinfo.io data
            const { loc } = data;
            if (loc) {
                const [latitude, longitude] = loc.split(',').map(parseFloat);
                setCoords({ latitude, longitude }); // Update coords with latitude and longitude
            } else {
                // Handle case where loc is not available
                setCoords({ latitude: null, longitude: null });
            }
        } catch (error) {
            console.error('Error fetching location by IP:', error);
            setLocation('Inconnue');
            setCountry('Inconnue');
            setCities([]);
            setCoords({ latitude: null, longitude: null });
        }
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchLocationByLatLng(latitude, longitude);
                },
                (error) => {
                    fetchLocationByIP();
                },
            );
        } else {
            fetchLocationByIP();
        }
    }, []);

    return { location, country, cities, coords };
};

export default useUserLocation;
