import React from 'react';
import styled from 'styled-components';
import ShopLogo from '../../ShopLogo';
import Share from '../../ShareShop';

const InfoContainer = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    text-align: center;
    padding: 10px;
  }

  .desc {
    font-size: 13px;
    color: grey;
    padding: 20px 0;
    text-align: center;
  }

  .location {
    display: flex;
    justify-content: center;
    width: 100%;
    /* text-align: center; */

    ion-icon {
      font-size: 20px;
      color: #037def;
    }

    @media screen and (max-width: 768px) {
      /* display: row; */
      width: 100%;
    }
  }

  .shopName {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .active,
    .block {
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }

    .active {
      background: #00cb00;
      box-shadow: 0 0 0 5px #00fa043f;
    }

    .block {
      background: #ff4545;
      box-shadow: 0 0 0 5px #ff45452e;
    }
  }
`;

const CustomButton = styled.button`
  padding: 10px 15px;
  border: 1px solid #000;
  border-radius: 20px;
  outline: none;
  background: #000;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
  margin: 10px auto ;

  &:hover {
    background: transparent;
    color: #000;
  }
`;

function ShopDetails({ isOwner, shopData, startEditing }) {
  return (
    <InfoContainer>
      <ShopLogo isOwner={isOwner} shopData={shopData} />

        <div className="shopName">
          <h3>{shopData.shop.shopName}</h3>
          {shopData.shop.isActive ? (
            <div className="active"></div>
          ) : (
            <div className="block"></div>
          )}
        </div>
        <p className="desc">{shopData.shop.shopDescription}</p>
        <p className="location">
          <ion-icon name="location"></ion-icon> {shopData.shop.shopAddress}
        </p>
        {isOwner && (
          <CustomButton onClick={startEditing}>Modifier le shop</CustomButton>
        )}

      <Share shopData={shopData} />
    </InfoContainer>
  );
}

export default ShopDetails;
