import React from 'react';
import styled from 'styled-components';
import GlobePhoto from '../../assets/ressources/World_map.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const ImgGlobe = styled.img`
    /* filter: drop-shadow(0 10px 10px rgba(255, 86, 86, 0.915)); */
    background: linear-gradient(100deg, #ffffff, transparent);
    width: 100%;
    /* position: absolute; */
    top: 0;

    @media screen and (max-width: 768px) {
        /* height: 100%; */
    }
`;

const TextZone = styled.div`
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* z-index: 1; */

    background: linear-gradient(190deg, #ffffff83, #fff);

    span {
        color: #35a7ff !important;
        font-size: 25px !important;
    }

    p {
        color: #777777 !important;
        font-size: 15px;
    }

    @media screen and (max-width: 768px) {
        /* left: 45%; */
        width: 80%;

        h1 {
            font-size: 17px;
        }

        span {
            font-size: 17px !important;
        }
        p {
            font-size: 13px;
        }
    }
`;

function WhyUs() {
    return (
        <div>
            <TextZone>
                <h1 data-aos="fade-up">
                    {' '}
                    Avec <span> elanga</span>, tout a été pensé et mis en oeuvre pour vous faciliter
                    la vie au maximum.{' '}
                </h1>

                <p data-aos="fade-up" data-aos-delay="500">
                    {' '}
                    Découvrez Elanga, la plateforme révolutionnaire qui vous permet de créer votre
                    propre entreprise en ligne en quelques clics seulement. Elanga va au-delà en
                    offrant un accès complet à toutes les activités commerciales imaginables. Grâce
                    à Elanga, vous pouvez transformer vos idées entrepreneuriales en réalité,
                    {/* que
                    vous soyez un créateur de mode, un artisan, un vendeur de produits numériques ou
                    même un prestataire de services. Avec une interface conviviale et des outils
                    puissants, Elanga simplifie le processus de création de votre boutique en ligne,
                    vous permettant de vous concentrer sur ce qui compte vraiment : développer votre
                    entreprise et atteindre vos objectifs.  */}
                    Rejoignez la révolution d'Elanga dès aujourd'hui et lancez-vous dans le monde du
                    commerce électronique avec facilité et simplicité.
                </p>
            </TextZone>
            <ImgGlobe src={GlobePhoto} alt="Globe picture" />
        </div>
    );
}

export default WhyUs;
