import styled from 'styled-components';
import './style.css';

const Container = styled.div`
    width: 100%;
    padding: 30px 10px;
    /* padding-top: 60px; */
    background-image: linear-gradient(#f4f4f4 1px, transparent 1px),
        linear-gradient(to right, #f4f4f4 1px, transparent 1px);
    background-size: 50px 50px;
    background-color: #ffffff;

    @media (max-width: 768px) {
        padding: 20px 5px;
        padding-top: 20px;
    }
`;

const Card = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    p {
        color: #424242 !important;
        font-size: 14px !important;
    }

    ion-icon {
        padding: 10px;
        border: 1px solid #000;
        border-radius: 50%;
        font-size: 24px;
        margin: 10px 0;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
`;

const CardsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 40px;
    padding: 20px;
    background: #fff;

    @media (max-width: 768px) {
        grid-gap: 20px;
    }
`;

function Cards() {
    return (
        <Container>
            <h3>Opportunités illimitées pour tout le monde</h3>
            <CardsGrid className="card">
                <Card>
                    <ion-icon name="cart-outline"></ion-icon>
                    <h4>Shop ou activité existant</h4>
                    <p>
                        Créez une version complète en ligne de votre activité physique existante,
                        permettez à vos clients d'accéder à tous vos articles directement sur votre
                        shop en un seul clic.
                    </p>
                </Card>
                <Card>
                    <ion-icon name="body-outline" className="second-ion-icon"></ion-icon>
                    <h4>100% en ligne</h4>
                    <p>
                        Vous pouvez démarrer quand vous voulez, où vous voulez. Aussi simple que
                        faire du vélo.
                    </p>
                </Card>
                <Card>
                    <ion-icon name="albums-outline"></ion-icon>
                    <h4>Indépendant</h4>
                    <p>
                        Fini les statuts WhatsApp qui ennuient vos contacts. Créez une collection de
                        tous vos articles et services, laissez vos clients faire le choix
                        simplement, pas besoin de remettre la liste chaque 24h.
                    </p>
                </Card>
                <Card>
                    <ion-icon name="layers-outline"></ion-icon>
                    <h4>Grossiste ou fournisseur</h4>
                    <p>
                        Revendez vos stocks directement aux revendeurs ou propriétaires de shop,
                        sans quitter le confort de votre salon.
                    </p>
                </Card>
            </CardsGrid>
        </Container>
    );
}

export default Cards;
