import styled from "styled-components";
import searchIcon from '../../../../assets/ressources/icones/search-icon.gif';
import { Link } from "react-router-dom";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    box-sizing: border-box;

    img{
        width: 300px;
        border-radius: 10px;
        box-shadow: 0 0 10px #a3a3a3;
        margin: 20px 0;
    }

    p{
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            transform: scale(1.05);
        }

        ion-icon{
        padding: 5px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px #a3a3a3;
        margin-left: 5px;
    }
    }


    p,a{
        display: flex;
        justify-content: center;
        align-items: center;
    }


    a{
        position: absolute;
        left: 0;
        bottom: 0;
        text-decoration: none;
        font-weight: 500;
        color: #000;
        transform: 0.3s;

        &:hover{
            font-weight: 600;
        }
    }

    @media screen and (max-width:768px) {
        height: 70vh;
    }
`


function NoArticle(){

    const hundleRechrge = ()=>{
        window.location.reload();
    }

    return(
        <Container>
            <h3> Aucun article trouvé ici. </h3>
            <img src={searchIcon} alt="" />

            <p onClick={hundleRechrge}> Recharger la page <ion-icon name="reload-outline"></ion-icon> </p>

            <Link to="/Marketplace" > <ion-icon name="storefront-outline"></ion-icon> Marketplace </Link>
        </Container>
    )
}

export default NoArticle;