import React, { useState, useEffect } from 'react';
import axios from 'axios';

const URLShortener = ({ longLink, onShortened }) => {
    const [error, setError] = useState(null);

    useEffect(() => {
        const shortenURL = async () => {
            try {
                // console.log('Attente de raccourissement de : ', longLink); // Log the long URL

                const response = await axios.get('https://is.gd/create.php', {
                    params: {
                        format: 'json',
                        url: longLink
                    }
                });

                // console.log('API Response:', response); // Log the response

                if (response.status === 200) {
                    onShortened(response.data.shorturl);
                } else {
                    throw new Error("Echec d'obtention du lien");
                }
            } catch (err) {
                // console.error('Error:', err); // Log any errors
                setError('Echec de raccourcissement du lien. Veuillez reessayez.');
            }
        };

        shortenURL();
    }, [longLink, onShortened]);

    if (error) {
        return <div>{error}</div>;
    }

    return null;
};

export default URLShortener;

































// import React, { useState } from 'react';
// import axios from 'axios';

// const URLShortener = () => {
//   const [longUrl, setLongUrl] = useState('');
//   const [shortUrl, setShortUrl] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   const handleShortenUrl = async () => {
//     setLoading(true);
//     setError('');
//     try {
//       const response = await axios.get('https://is.gd/create.php', {
//         params: {
//           format: 'json',
//           url: longUrl,
//         },
//       });
//       if (response.data.shorturl) {
//         setShortUrl(response.data.shorturl);
//       } else {
//         setError('Failed to shorten URL. Please try again.');
//       }
//     } catch (err) {
//       setError('Failed to shorten URL. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div>
//       <h2>Raccourcisseur d'URL</h2>
//       <input
//         type="text"
//         value={longUrl}
//         onChange={(e) => setLongUrl(e.target.value)}
//         placeholder="Entrez l'URL à raccourcir"
//       />
//       <button onClick={handleShortenUrl} disabled={loading}>
//         {loading ? 'Raccourcissement en cours...' : 'Raccourcir l\'URL'}
//       </button>
//       {shortUrl && (
//         <div>
//           <p>URL raccourcie:</p>
//           <a href={shortUrl} target="_blank" rel="noopener noreferrer">
//             {shortUrl}
//           </a>
//         </div>
//       )}
//       {error && <p style={{ color: 'red' }}>{error}</p>}
//     </div>
//   );
// };

// export default URLShortener;
