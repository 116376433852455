import React from 'react';
import styled from 'styled-components';

const TestimonialContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    background-color: #f9f9f9;

    @media (max-width: 768px) {
        padding: 30px 10px;
    }
`;

const TestimonialCard = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    padding: 20px;
    max-width: 800px;
    width: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Avatar = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;

    @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 10px;
    }
`;

const TextWrapper = styled.div`
    flex: 1;
`;

const Name = styled.h3`
    color: #333;
    margin: 10px 0 5px;
`;

const Position = styled.p`
    color: #777;
    margin: 5px 0 15px;
    font-size: 0.9rem;
`;

const Quote = styled.p`
    color: #555;
    font-style: italic;
    font-size: 1rem;
    line-height: 1.5;
`;

const testimonials = [
    {
        name: 'Jean Dupont',
        position: 'Entrepreneur',
        quote: 'Elanga a transformé ma façon de faire des affaires en ligne. La plateforme est incroyablement facile à utiliser et très efficace.',
        avatar: 'https://via.placeholder.com/80',
    },
    {
        name: 'Marie Curie',
        position: 'Artisan',
        quote: "Grâce à Elanga, j'ai pu étendre ma boutique d'artisanat en ligne et atteindre de nouveaux clients.",
        avatar: 'https://via.placeholder.com/80',
    },
    {
        name: 'Paul Martin',
        position: 'Consultant',
        quote: "La simplicité et la flexibilité d'Elanga m'ont permis de lancer ma boutique en ligne en un temps record.",
        avatar: 'https://via.placeholder.com/80',
    },
];

function Testimonials() {
    return (
        <TestimonialContainer>
            {testimonials.map((testimonial, index) => (
                <TestimonialCard key={index}>
                    <Avatar src={testimonial.avatar} alt={`${testimonial.name}'s avatar`} />
                    <TextWrapper>
                        <Name>{testimonial.name}</Name>
                        <Position>{testimonial.position}</Position>
                        <Quote>"{testimonial.quote}"</Quote>
                    </TextWrapper>
                </TestimonialCard>
            ))}
        </TestimonialContainer>
    );
}

export default Testimonials;
