import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animation pour le Skeleton
const loading = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const SkeletonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;

  @media screen and (max-width:768px){
    grid-template-columns: repeat(2, 1fr);
  }
`;

const SkeletonItem = styled.div`
  background-color: #f0f0f0;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.4), rgba(255,255,255,0));
    animation: ${loading} 1.5s infinite;
  }
`;

const SkeletonImage = styled.div`
  width: 100%;
  height: 150px;
  background-color: #e0e0e0;
  border-radius: 4px;
`;

const SkeletonText = styled.div`
  width: ${(props) => props.width || '100%'};
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 10px;
`;

const Skeleton = () => {
  return (
    <SkeletonWrapper>
      {Array.from({ length: 6 }).map((_, index) => (
        <SkeletonItem key={index}>
          <SkeletonImage />
          <SkeletonText width="60%" />
          <SkeletonText width="80%" />
          <SkeletonText width="40%" />
        </SkeletonItem>
      ))}
    </SkeletonWrapper>
  );
};

export default Skeleton;
