import styled from 'styled-components';

const BurgerContainer = styled.div`
  padding: 10px;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 30px;
  height: 24px;
  justify-content: space-between;

  &.active > div:nth-child(1) {
    transform: rotate(45deg);
    transform-origin: 5px 1px;
    width: 20px;
    background: #000;
  }

  &.active > div:nth-child(2) {
    opacity: 0;
  }

  &.active > div:nth-child(3) {
    transform: rotate(-45deg);
    transform-origin: 5px 0;
    width: 20px;
    background: #000;
  }
`;

const MenuLine = styled.div`
  height: 3px;
  width: 100%;
  /* background: #fff; */
  border-radius: 5px;
  transition: all 0.3s ease;
  /* box-shadow: 0 0 5px #b8b8b8; */
    background: ${(props) => (props.$isFixed ? '#000' : '#fff')};

  
  &:nth-child(1){
    width: 15px;
  }

  &:nth-child(2){
    width: 22px;
 }

`;

function MenuBurger({ isFixed, isBurgerActive, setBurgerIsActive }) {
  function handleClick() {
    setBurgerIsActive(!isBurgerActive);
  }

  return (
    <BurgerContainer>
      <Menu onClick={handleClick} className={isBurgerActive ? 'active' : ''}>
        <MenuLine $isFixed={isFixed}></MenuLine>
        <MenuLine $isFixed={isFixed}></MenuLine>
        <MenuLine $isFixed={isFixed}></MenuLine>
      </Menu>
    </BurgerContainer>
  );
}

export default MenuBurger;
