import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  height: 50mm;
  width: 84mm;
  position: relative;
  perspective: 800px;

  &:hover .card__side_front {
    transform: rotateY(180deg);
  }

  &:hover .card__side_back {
    transform: rotateY(0deg);
  }
`;

const CardSide = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 3.18mm;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transition: transform 0.7s ease-out;
  cursor: pointer;
  padding: 10px;
`;

const CardFront = styled(CardSide)`
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, #242424 100%);
  transform: rotateY(0deg);
`;

const CardBack = styled(CardSide)`
  background: linear-gradient(-90deg, rgb(0, 0, 0) 0%, #242424 100%);
  transform: rotateY(-180deg);
  color: #eeeeee;
`;

const Flex1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Flex2 = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
`;

const NameBank = styled.p`
  font-family: Inter, sans-serif;
  font-weight: 500;
  position: relative;
  font-size: 22px;
  margin-left: 8px;
  color: white;

  &::after {
    content: "By safaridew";
    position: absolute;
    font-size: 6px;
    top: 105%;
    left: 21%;
    color: #635c77;
  }

  &::before {
    content: "₴";
    position: absolute;
    top: 0;
    right: 0;
    color: #635c77;
  }
`;

const Chip = styled.div`
  width: 1.3cm;
  height: 1cm;
  margin-left: 22px;
  margin-top: -35px;
  background: rgb(226, 175, 35);
  border-radius: 8px;
`;

const NamePerson = styled.p`
  text-transform: uppercase;
  font-family: 'Roboto Mono', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 20px;
  position: relative;
  display: block;
  color: white;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 45px;
    aspect-ratio: 1 / 1;
    background: red;
    bottom: -10px;
    right: 0px;
    border-radius: 50%;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 45px;
    aspect-ratio: 1 / 1;
    background: orange;
    bottom: -10px;
    right: 23px;
    border-radius: 50%;
  }
`;

const BlackStrip = styled.div`
  background: black;
  width: 100%;
  height: 50px;
  border-radius: 3.18mm 3.18mm 0 0;
  position: absolute;
  top: 0;
  right: 0;
`;

const CardNumber = styled.p`
  font-size: 18px;
  font-family: 'Roboto Mono', sans-serif;
  color: #eeeeee;
  margin: 45px 0px 15px 10px;
`;

const OtherNumbers = styled.p`
  font-family: 'Roboto Mono', sans-serif;
  color: #eeeeee;
  display: block;
  margin-left: 10px;
  font-size: 12px;
  position: relative;

  &::after {
    color: #635c77;
    position: absolute;
    font-size: 8px;
    left: 0;
    bottom: 60px;
  }
`;

const ExpiryDate = styled(OtherNumbers)`
  &::after {
    content: "Expire à";
  }
`;

const Cvc = styled(OtherNumbers)`
  &::after {
    content: "CVC";
  }
`;

const Photo = styled.div`
  width: 1.4cm;
  height: 1.4cm;
  position: absolute;
  left: 12px;
  bottom: 15px;
  background: grey;
  border-radius: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Debit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8cm;
  height: 1cm;
  border-radius: 1cm;
  background: #c0c0c0;
  position: absolute;
  right: 12px;
  bottom: 25px;
  font-family: Inter;
  color: #666666;

  &::after {
    content: "";
    display: block;
    position: absolute;
    background: rgba(166, 163, 163, 0.7);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    right: 0;
  }
`;

const OtherInfo = styled.p`
  color: #635c77;
  font-size: 4px;
  text-align: center;
  font-family: 'Roboto Mono', sans-serif;
  position: absolute;
  bottom: 10px;
  left: 38px;
  backface-visibility: hidden;
`;

const Balance = ({user, balance}) => {
  return (
    <CardContainer>
      <CardFront className="card__side card__side_front">
        <Flex1 className="flex__1">
          <NameBank className="card__side__name-bank"> USD {balance}</NameBank>
          <Chip className="card__side__chip"></Chip>
          <NamePerson className="card__side__name-person"> {user.firstName}  {user.lastName}</NamePerson>
        </Flex1>
      </CardFront>
      <CardBack className="card__side card__side_back">
        <BlackStrip className="card__side__black"></BlackStrip>
        <CardNumber className="card__side__number">XXXX XXXX XXXX XXXX</CardNumber>
        <Flex2 className="flex__2">
          <ExpiryDate className="card__side__other-numbers card__side__other-numbers_1">XX/XX</ExpiryDate>
          <Cvc className="card__side__other-numbers card__side__other-numbers_2">XXX</Cvc>
          <Photo className="card__side__photo"> elanga </Photo>
          <Debit className="card__side__debit">debit</Debit>
        </Flex2>
        <OtherInfo className="card__side__other-info">
          MONOBANK.UA | 0 800 205 205 | АТ "УНІВЕРСАЛ БАНК". ЛІЦЕНЗІЯ НБУ №92 ВІД 20.01.1994 | PCE PC100650 WORLD DEBIT
        </OtherInfo>
      </CardBack>
    </CardContainer>
  );
};

export default Balance;
