import React from "react";
import styled, { keyframes } from "styled-components";

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

// Styled Components
const Container = styled.div`
  background: linear-gradient(135deg, #ff6f61, #ffcc5c);
  border-radius: 10px;
  height: 65vh;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: ${fadeIn} 2s ease-in-out;

  @media (max-width: 768px) {
    height: 50vh;
  }

  @media (max-width: 480px) {
    height: 50vh;
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #fff;
  margin-bottom: 20px;
  animation: ${slideIn} 1.5s ease-in-out;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
    margin-bottom: 10px;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: #fff;
  animation: ${slideIn} 2s ease-in-out;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const Button = styled.button`
  background-color: #fff;
  color: #ff6f61;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #000000;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    margin-top: 10px;
  }
`;

function Header() {
  const handleButtonClick = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <Title>Découvrez toutes les boutiques sur Elanga </Title>
      <Subtitle>Explorez une variété de boutiques pour trouver ce que vous cherchez.</Subtitle>
      <Button onClick={handleButtonClick}>Commencer à explorer</Button>
    </Container>
  );
}

export default Header;
