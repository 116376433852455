import axios from 'axios';

const PEXELS_API_KEY = process.env.REACT_APP_PEXELS_API_KEY; // Remplace par ta clé API Pexels

async function getRandomImage(category) {
    try {
        const response = await axios.get(
            `https://api.pexels.com/v1/search?query=${category}&per_page=1`,
            {
                headers: {
                    Authorization: PEXELS_API_KEY,
                },
            },
        );
        const imageUrl = response.data.photos[0].src.large;
        return imageUrl;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export default getRandomImage;
