import React, { createContext, useContext, useState } from 'react';

// Créer un contexte pour les métadonnées
const MetaContext = createContext();

// Créer un provider pour envelopper l'application
export const MetaProvider = ({ children }) => {
  const [metaData, setMetaData] = useState({
    title: 'Elanga | app',
    icon: 'https://www.zarla.com/images/zarla-shop-stock-1x1-2400x2400-20220124-jyf4xp648jqxjqcjj9dc.png?crop=1:1,smart&width=250&dpr=2',
  });
  // icon: '../src/assets/img_marque/elanga_default.png',


  //   console.log(metaData);

  return (
    <MetaContext.Provider value={{ metaData, setMetaData }}>
      {children}
    </MetaContext.Provider>
  );
};

// Créer un hook pour accéder au contexte
export const useMeta = () => useContext(MetaContext);
