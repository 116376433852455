import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import { getToken } from '../../utils/Cookies/getCookie';
import Loader from '../LoadButton';
import { Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import './style.css';

const Container = styled.div`
    padding: 5px;
    position: fixed;
    z-index: 2;
    background: #000000;
    border-radius: 50%;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0 0 0 10px #494949;

    ion-icon {
        font-size: 25px;
        color: #fff;
        transition: 0.3s;

        &:hover {
            transform: scale(1.3);
        }
    }
`;

const StyledLink = styled(Link)`
    padding: 0 !important;
    margin: 0 !important;
    color: #a8a8a8 !important;
    font-weight: 600;
`;

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    }
    return text;
};

function AccessShop({ isAuthenticated }) {
    const [isConnected, setIsConnected] = useState(false);
    const token = getToken();
    const [loading, setLoading] = useState(false);
    const [shops, setShops] = useState([]);
    const containerRef = useRef(null);

    useEffect(() => {
        const fetchDataShop = async () => {
            setLoading(true);
            try {
                if (token) {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shop/user`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setShops(data.shops);
                        // console.log(data.shops);
                    } else {
                        console.error(
                            'Erreur lors de la récupération des shops:',
                            response.statusText,
                        );
                    }
                }
            } catch (error) {
                console.error('Erreur:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataShop();
    }, [token]);

    useEffect(() => {
        if (isAuthenticated) {
            setIsConnected(true);
        } else {
            setIsConnected(false);
        }
    }, [isAuthenticated]);

    const menuItems = shops.map((shop) => ({
        key: shop._id,
        label: <StyledLink to={`/Shop/${shop._id}`}>{truncateText(shop.shopName, 15)}</StyledLink>,
    }));

    return (
        isConnected &&
        shops.length > 0 && (
            <Dropdown
                menu={{ items: menuItems }}
                trigger={['click']}
                getPopupContainer={() => containerRef.current}
            >
                <Container ref={containerRef}>
                    {!loading ? (
                        <ion-icon name="layers-outline"></ion-icon>
                    ) : (
                        <Loader
                            height="20px"
                            width="20px"
                            $borderColor="#c4c4c4"
                            $background="transparent"
                        />
                    )}
                </Container>
            </Dropdown>
        )
    );
}

export default AccessShop;
