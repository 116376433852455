import styled from "styled-components";
import ArticleItem from '../ArticleItem';
import PropTypes from 'prop-types';

const ArticleListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
`;

function ArticleList({ articles, showDrawer, isOwner, shopData, ref3 }) {
  return (
    <ArticleListWrapper>
      {articles.map((article) => (
        <ArticleItem key={article._id} article={article} showDrawer={showDrawer} isOwner={isOwner} shopData={shopData} ref3={ref3}  />
      ))}
    </ArticleListWrapper>
  );
}

ArticleList.propTypes = {
  ref3: PropTypes.object.isRequired,
};

export default ArticleList;
