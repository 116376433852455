import React from 'react';
import styled from 'styled-components';
import { Form, Input } from 'antd';

const SocialLinkContainer = styled.div`
  padding: 20px 0;
  text-align: center;
`;

const SocialLinkItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  /* background: red; */
`;

const SocialIcon = styled.div`
  /* margin-right: 10px; */
  margin-bottom: -40px;
  font-size: 30px;
  color: #999999;
`;

const CustomInput = styled(Input)`
  flex: 1;
  padding: 10px 5px;
`;

const SocialLink = ({
  ownerInfo,
  whatsapp,
  setWhatsapp,
  facebookLink,
  setFacebookLink,
  instagramLink,
  setInstagramLink,
  tiktokLink,
  setTiktokLink,
  handleSave,
  handleCancel,
  isUpdating,
}) => {

    // console.log(ownerInfo.whatsapp);
  return (
    <SocialLinkContainer>
      <Form layout="vertical">

        <SocialLinkItem>
          <SocialIcon> <ion-icon name="logo-whatsapp"></ion-icon> </SocialIcon>
          <Form.Item label="WhatsApp ( Ex : +123... )" style={{ flex: 1, marginBottom: 0 }}>
            <CustomInput value={whatsapp ? whatsapp : ownerInfo.whatsapp} onChange={(e) => setWhatsapp(e.target.value)} />
          </Form.Item>
        </SocialLinkItem>

        <SocialLinkItem>
          <SocialIcon> <ion-icon name="logo-facebook"></ion-icon> </SocialIcon>
          <Form.Item label="Page Facebook de votre shop" style={{ flex: 1, marginBottom: 0 }}>
            <CustomInput value={facebookLink} onChange={(e) => setFacebookLink(e.target.value)} placeholder="Ex : safaridew" />
          </Form.Item>
        </SocialLinkItem>

        <SocialLinkItem>
          <SocialIcon> <ion-icon name="logo-instagram"></ion-icon> </SocialIcon>
          <Form.Item label="Instagram de votre shop" style={{ flex: 1, marginBottom: 0 }}>
            <CustomInput value={instagramLink} onChange={(e) => setInstagramLink(e.target.value)} placeholder="Ex : safaridew_" />
          </Form.Item>
        </SocialLinkItem>

        <SocialLinkItem>
          <SocialIcon> <ion-icon name="logo-tiktok"></ion-icon> </SocialIcon>
          <Form.Item label="Pseudo TikTok de votre shop" style={{ flex: 1, marginBottom: 0 }} >
            <CustomInput value={tiktokLink} onChange={(e) => setTiktokLink(e.target.value)} placeholder="Ex : @safaridew" />
          </Form.Item>
        </SocialLinkItem>
        
      </Form>
      {/* <div style={{ textAlign: 'right', marginTop: 20 }}>
        <button onClick={handleCancel}>Annuler</button>
        <button onClick={handleSave}>
          {isUpdating ? 'Enregistrement...' : 'Sauvegarder'}
        </button>
      </div> */}
    </SocialLinkContainer>
  );
};

export default SocialLink;
