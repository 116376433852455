import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/img_marque/logo-color.svg';
import logoWhite from '../../assets/img_marque/logo-white.svg';
import PropTypes from 'prop-types';
import { getToken } from '../../utils/Cookies/getCookie';
import { setCookie } from '../../utils/Cookies/setCookie';
import MenuBurger from '../MenuBurger';
import { useAuthModal } from '../../utils/Context/AuthModal';

const Logo = styled.img`
    width: ${(props) => (props.$isFixed ? '80px' : '100px')};
    height: 55px;
    object-fit: cover !important;
    transition: 0.3s;
    z-index: 3;
`;

const Liens = styled.div`
    margin-right: 20px;
    transition: 0.5s;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0 0 5px grey;
        backdrop-filter: blur(10px) !important;
        -webkit-backdrop-filter: blur(10px);
        transform: ${(props) => (props.$isBurgerActive ? 'translateX(20%)' : 'translateX(100%)')};
    }
`;

const LoginProfil = styled.button`
    padding: 10px 20px;
    color: #000;
    border-radius: 20px;
    border: 1px solid #fff;
    outline: none;
    cursor: pointer;
    font-weight: 700;
    transition: 0.5s;
    background: ${(props) => (props.$isFixed ? '#000' : '#fff')};
    color: ${(props) => (props.$isFixed ? '#fff' : '#000')};

    &:hover {
        color: ${(props) => (props.$isFixed ? '#000' : '#fff')};
        border-color: ${(props) => (props.$isFixed ? '#000' : '#fff')};
        background: transparent;
        background: ${(props) => (props.$isFixed ? '#fff' : 'transparent')};
        box-shadow: 0 0 55px 0 #fff;
    }

    @media screen and (max-width: 768px) {
        position: fixed;
        display: flex !important;
        top: 15px;
        left: 25px;
        background: ${(props) => (props.$isFixed ? '#fff' : '#000')};
        border-color: #000;
        color: ${(props) => (props.$isFixed ? '#000' : '#fff')};
    }
`;

const NavBar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${(props) => (props.$isFixed ? '5px' : '10px')};
    position: fixed;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    top: 0;
    left: 0;
    background-color: ${(props) => (props.$isFixed ? '#fff' : '#43434347')};
    box-shadow: ${(props) => (props.$isFixed ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none')};
    transition: 0.5s;
    z-index: 2;

    @media screen and (max-width: 768px) {
        box-shadow: ${(props) => (props.$isFixed ? 'none' : 'none')};
        backdrop-filter: ${(props) => (props.$isFixed ? 'blur(2px)' : 'blur(2px)')};
        padding: ${(props) => (props.$isFixed ? '2px' : '5px')};
    }
`;

const LinkNav = styled(Link)`
    position: relative;
    padding: 5px 15px;
    color: ${(props) => (props.$isFixed ? 'grey' : '#000')};
    font-weight: 500;
    margin: 0 20px;
    padding: 10px 20px;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    font-size: ${(props) => (props.$isFixed ? '14px' : '15px')};
    transition: 0.5s;
    color: ${(props) => (props.$isFixed ? '#2b2b2b' : '#fff')};

    &:hover {
        color: #008ae6;
    }

    @media screen and (max-width: 768px) {
        color: #000;
    }

    ion-icon {
        font-size: 20px;
    }

    .usernav {
        /* Styles spécifiques pour usernav */
    }
`;

const UserImg = styled.img`
    width: 25px;
    border-radius: 50%;
`;

function Navbar() {
    const [isFixed, setIsFixed] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isBurgerActive, setBurgerIsActive] = useState(false);
    const [userPic, setUserPic] = useState('');

    const { authModal, setAuthModal } = useAuthModal();

    const toggleAuthModal = () => {
        setAuthModal((prev) => !prev); // Toggle authModal using setAuthModal
    };

    const token = getToken();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop > lastScrollTop) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }

            setLastScrollTop(scrollTop <= 0 ? 1 : scrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (token) {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/userinfo`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const userData = await response.json();
                        if (userData.picture === 'placeholder.png') {
                            setUserPic(
                                'https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png',
                            );
                        } else {
                            setUserPic(userData.picture);
                        }
                        setCookie('userData', userData, 60);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [token]);

    return (
        <NavBar $isFixed={isFixed} $isBurgerActive={isBurgerActive}>
            <Link to="/">
                <Logo src={isFixed ? logo : logoWhite} alt="Logo safaridew" $isFixed={isFixed} />
            </Link>

            <Liens $isBurgerActive={isBurgerActive}>
                <LinkNav $isFixed={isFixed} to="/" onClick={() => setBurgerIsActive(false)}>
                    Accueil
                </LinkNav>
                <LinkNav $isFixed={isFixed} to="/Marketplace" onClick={() => setBurgerIsActive(false)}>
                    Marketplace
                </LinkNav>
                <LinkNav $isFixed={isFixed} to="/Prix" onClick={() => setBurgerIsActive(false)}>
                    Prix
                </LinkNav>
                {token ? (
                    <LinkNav $isFixed={isFixed} to="/Dashboard" onClick={() => setBurgerIsActive(false)}>
                        <div className="usernav">
                            <UserImg src={userPic} alt="User Profile" />
                        </div>
                    </LinkNav>
                ) : (
                    <LoginProfil $isFixed={isFixed} onClick={toggleAuthModal}>
                        Connexion
                    </LoginProfil>
                )}
            </Liens>
            <MenuBurger isFixed={isFixed} isBurgerActive={isBurgerActive} setBurgerIsActive={setBurgerIsActive} />
        </NavBar>
    );
}

Navbar.propTypes = {
    isFixed: PropTypes.bool,
    lastScrollTop: PropTypes.number,
    location: PropTypes.object,
};

export default Navbar;
