import React, { useState } from "react";
import styled from "styled-components";
import ImageLoader from "../../../../../components/ImageLoader";

const ArticleItemWrapper = styled.div`
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  .delivery {
    position: absolute;
    right: 5px;
    bottom: 2px;
    font-size: 9px;
    color: #666666ed;
    font-weight: 600;
  }
`;

const ArticleImages = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
`;

const ArticlesImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #ffffff;
  border-radius: 10px;
  transition: 0.3s;

  /* &:nth-child(1) {
    z-index: 3;
  }
  &:nth-child(2) {
    z-index: 2;
    top: 5px;
    transform: rotate(-10deg);
    box-shadow: 0 0 10px grey;
  }
  &:nth-child(3) {
    z-index: 1;
    top: 10px;
    transform: rotate(15deg);
  } */

  &:nth-child(1) {
    z-index: 3;
    box-shadow: 0 5px 10px #0000005d;
  }

  &:nth-child(2) {
    z-index: 2;
    top: 10px;
    left: 5px;
    width: 95%;
    margin: auto;
    box-shadow: 0 5px 10px #c9c9c9;
  }

  &:nth-child(3) {
    z-index: 1;
    top: 20px;
    left: 10px;
    width: 85%;
    box-shadow: 0 5px 10px #c9c9c9;
  }

  &:hover {
    transform: none;
  }
`;

const ArticleDetails = styled.div`
  padding: 15px 0;
  width: 100%;
  position: relative;

  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
  }
`;

function ArticleItem({ article, showDrawer, isOwner, shopData, ref3 }) {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <ArticleItemWrapper onClick={() => showDrawer(article)} ref={ref3}>
      <ArticleImages>
        { loading && <ImageLoader /> }
        {article.image && article.image.length > 0 ? (
          article.image.map((imgSrc, index) => (
            <ArticlesImg
              key={index}
              src={imgSrc}
              alt={`Article image ${index + 1}`}
              onLoad={handleImageLoad}
              style={{ display: loading ? "none" : "block" }}
            />
          ))
        ) : (
          <ArticlesImg
            src={article.image}
            alt="Random image"
            onLoad={handleImageLoad}
            style={{ display: loading ? "none" : "block" }}
          />
        )}
      </ArticleImages>
      <ArticleDetails>
        <h4>{article.title}</h4>
      </ArticleDetails>
      {article.isDeliverable && <p className="delivery"> Livraison </p>}
    </ArticleItemWrapper>
  );
}

export default ArticleItem;
