// categoryTranslations.js

const categoryTranslations = {
    Alimentation: 'food',
    Animaux: 'pets',
    'Art et artisanat': 'arts and crafts',
    'Beauté et soins personnels': 'beauty and personal care',
    Bijouterie: 'jewelry',
    'Brico et jardinage': 'DIY and gardening',
    Communication: 'communication',
    Conseil: 'consulting',
    Construction: 'construction',
    'Développement logiciel': 'software development',
    Divertissement: 'entertainment',
    Électronique: 'electronics',
    Épicerie: 'grocery',
    'Équipement sportif': 'sporting goods',
    'Formation et éducation': 'education and training',
    'Formation professionnelle': 'professional training',
    Grossiste: 'wholesale',
    Habillement: 'clothing',
    'Jouets et jeux': 'toys and games',
    'Livres et presse': 'books and press',
    'Maison et décoration': 'home and decor',
    Maintenance: 'maintenance',
    'Matériel de bureau': 'office supplies',
    'Matériel médical': 'medical equipment',
    Mobilier: 'furniture',
    Musique: 'music',
    Nettoyage: 'cleaning',
    Photographie: 'photography',
    Réparation: 'repair',
    Santé: 'health',
    'Services de conception web': 'web design services',
    'Services de gestion': 'management services',
    'Services de gestion des réseaux': 'network management services',
    'Services de marketing': 'marketing services',
    'Services de restauration': 'catering services',
    'Services de support technique': 'technical support services',
    'Services de traduction': 'translation services',
    'Services de transport': 'transport services',
    'Services de consultation': 'consulting services',
    'Sécurité & Gardiennage': 'security services',
    'Téléphones & Ordinateurs': 'phones and computers',
    Véhicules: 'vehicles',
    Voyages: 'travel',
    Téléphonie: 'telephony',
    // Ajoutez plus de traductions ici
};

function translateCategory(category) {
    return categoryTranslations[category] || category; // Retourne la traduction ou la catégorie originale si non trouvée
}

export default translateCategory;
