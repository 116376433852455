import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { MetaProvider } from './utils/Context/MetaContext';
import MetaUpdater from './utils/Context/MetaUpdater';

// ====== Import Pages ==========//
import Home from './pages/Home';
import Marketplace from './pages/Marketplace';
import Shop from './pages/Shop';
import Dashboard from './pages/Dashboard';

// =====Import Global Components //
import NotFound from './pages/NotFound';

// ======= IMPORT CONTEXT ========== //
import { AuthModalProvider } from './utils/Context/AuthModal';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Google Sans', sans-serif; */
    font-family: 'Poppins';
  }

  body {
    background: #f1f1f1;
  }

  body {
    /* background-color: ${(props) => (props.theme === 'light' ? '#f1f1f1' : '#2b2b2b')}; */
    /* color: ${(props) => (props.theme === 'light' ? '#2e2e2e' : '#c5c5c5')}; */
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
`;

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then((registration) => {
                // console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((error) => {
                // console.log('Service Worker registration failed:', error);
            });
    });
}

// BACKEND RENDER.COM ====  https://ouigo-backend-application.onrender.com
// BACKEND LIHGTSAIL === http://54.229.4.47:3500

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <MetaProvider>
            <Router>
                <MetaUpdater />
                <AuthModalProvider>
                    <GlobalStyle />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="*" element={<NotFound />} />
                        <Route path="/Dashboard" element={<Dashboard />} />
                        <Route path="/Marketplace" element={<Marketplace />} />
                        <Route path="/Shop/:id" element={<Shop />} />
                    </Routes>
                </AuthModalProvider>
            </Router>
        </MetaProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// const toggleAuthModal = () => {
//   setAuthModal(!authModal);
//   // console.log(authModal);
// };
